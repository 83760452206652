import React from 'react';
import {
  Typography,
  Stack,
  Box
} from "@mui/material";
const {Theme} = require("../theme");
const { InfoBox } = Theme;
const { userProfileObj } = require("../config");
import Grid from '@mui/material/Grid';
import { InputErrors, User } from '../CustomisableUserProfilesController';
import InputBase from "./InputBase.web"
import { getPersonalInfoInput } from './AddNewDependant';
import { MuiTelInput } from "mui-tel-input";

export interface EditProps {
  userInput: Omit<User, "participantId">;
  inputErrors: InputErrors;
  handleInput: any;
  userDetails: User;
  errorMessages: Record<string, string>;
}

const EditUserProfile = ({ userDetails, userInput, inputErrors, handleInput, errorMessages }: EditProps) => { 
  return (
    <Grid container className='dependant-block' style={styles.editProfile} rowGap={"64px"}>
      {getPersonalInfoInput({ userInput, inputErrors, handleInput, errorMessages })}
      <Grid item xs={12} lg={6} className='pl-2'>
        <InfoBox gap={"16px"}>
          <Typography component={"h1"} className="infoBox-headline">{userProfileObj.contactTxt}</Typography>
          <Stack gap={"24px"}>
            <Stack gap={"4px"}>
              <Typography className="infoBox-title" style={styles.label} >{userProfileObj.phoneNumberTxt}</Typography>
              <MuiTelInput 
                value={userInput.phoneNumber}
                onChange={(value) => handleInput("phoneNumber", value)} 
                defaultCountry="US"
                name='phoneNumber'
                placeholder="+1 000 000 0000"
                error={inputErrors.phoneError}
                className="infoBox-data"
              />
              {inputErrors.phoneError ?
                  <Box sx={styles.errorMsg}>{errorMessages.phoneNumError}</Box> : null}
            </Stack>
            <InputBase
              label={userProfileObj.emailTxt}
              value={userInput.email}
              error={inputErrors.emailError}
              name='email'
              inputProps={{ maxLength: 64 }}
              placeholder="Enter email"
              handleInput={handleInput} testId={'email'} errorTitle={errorMessages.emailError}
            />
          </Stack>
        </InfoBox>
      </Grid>
      <Grid item xs={12} lg={12}>
        <InfoBox gap={"16px"}>
          <Typography component={"h1"} className="infoBox-headline">{userProfileObj.phpMembershipTxt}</Typography>
          <Stack className='gap-64' style={{ flexDirection: "row" }}>
            <Stack>
              <Typography className="infoBox-title" style={styles.label}>{userProfileObj.participantIdTxt}</Typography>
              <Typography className="infoBox-data">{userDetails.participantId}</Typography>
            </Stack>
          </Stack>
        </InfoBox>
      </Grid>
    </Grid>
  )
}

const styles = {
  editProfile: {
    marginTop: "2rem",
    maxWidth: "calc(100% - 100px)",
    padding: "0 0 0 2rem"
  },
  phoneAdornment: {
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: "Inter"
  },
  errorMsg: {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: "Inter"
  },
  label: {
    fontSize: '14px'
  },
}

export default EditUserProfile