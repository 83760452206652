Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.postContactUsAPiEndPoint = "/bx_block_contact_us/contacts";
exports.getCustomizableProfileEndPoint = "/bx_block_profile/profile";
exports.listDependentEndPoint = "bx_block_profile/dependents/";


exports.select1Title = "Preferred method of contact";
exports.select2Title = "Who are you submitting this request for";
exports.commentsTitle = "Details of the Issue";

exports.descPlaceHolder = "Provide details of your request                                                                                   ";

exports.labelTitleText = "contact us";
exports.labelBodyText = "Talk to us";
exports.paraBodyText = "Fill in the form below, and we will get back to you as soon as possible!";

exports.btnExampleTitle = "Submit";
// Customizable Area End