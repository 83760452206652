import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import { Stack, Typography } from '@mui/material/';
import DialogContent from '@mui/material/DialogContent';
import { phcPic } from "../assets"
import { sx } from "./SuccessDialog.web";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardNumberElementChangeEvent } from "@stripe/stripe-js";
const configJSON = require("../config.js")
import { CardErrorType, Props } from '../types.web';
import PaymentMethod from "./PaymentMethod.web"

interface State {
    cardErrors: CardErrorType
}
export const initialCardErrors = { cardNumberError: "", cardExpiryError: "", cardCVCError: "" };
export default class StripeUpdatePaymentMethod extends Component<Props, State> {
    stripePromise = loadStripe(configJSON.STRIPE_PUBLISHABLE_KEY);
    allowedBrands = ['visa', 'mastercard', 'amex'];

    constructor(props: Props) {
        super(props);
        this.state = {
            cardErrors: initialCardErrors
        }
    }

    handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
        if (event.complete) {
            const detectedBrand = event.brand;
            if (!this.allowedBrands.includes(detectedBrand)) {
                this.setState({ cardErrors: { ...this.state.cardErrors, cardNumberError: "Only Visa, Mastercard, and American Express are accepted." } });
            } else {
                this.setState({ cardErrors: { ...this.state.cardErrors, cardNumberError: "" } });
            }
        } else if (event.error) {
            this.setState({ cardErrors: { ...this.state.cardErrors, cardNumberError: event.error.message } });
        }
    };
    setErrors = (errors: CardErrorType) => {
        this.setState({ cardErrors: errors })
    }
    handleError = (error: any) => {
        let cardNumberError = error.type?.includes("card_error") ? error.message || error.code : "";
        let cardExpiryError = error.code?.includes("invalid_expiry_") ? error.message || error.code : "";
        let cardCVCError = error.code?.includes("incomplete_cvc") ? error.message || error.code : ""
        this.setState({ cardErrors: { ...this.state.cardErrors, cardNumberError, cardExpiryError: cardExpiryError, cardCVCError: cardCVCError } });
    }
    render() {
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                sx={{
                    ...sx,
                    ".MuiDialog-paperWidthSm": {
                        borderRadius: "16px",
                        padding: "12px 0",
                        maxWidth: "880px",
                        width: "100%",
                        fontFamily: "Inter"
                    },
                    ".MuiDialogContent-root": {
                        overflow: "visible",
                        padding: "24px"
                    }
                }}
            >
                <DialogContent>
                    <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} gap={"56px"}>
                        <Stack justifyContent={"center"} alignItems={"center"} gap={"8px"}>
                            <img src={phcPic.default} style={{ width: "75px", height: "75px" }} />
                            <Stack justifyContent={"center"} alignItems={"center"} gap={"4px"} mb={"1.5rem"}>
                                <Typography sx={{
                                    color: "#0F172A",
                                    fontSize: "24px",
                                    fontFamily: "Inter"
                                }}>Updating payment method</Typography>
                                <Typography sx={{
                                    color: "#64748B",
                                    fontSize: "14px",
                                    fontFamily: "Inter"
                                }}>Please fill in all mandatory fields below to update the payment method</Typography>
                            </Stack>
                            <Elements stripe={this.stripePromise} options={{
                                appearance: { theme: "stripe" }
                            }}>
                                <PaymentMethod handleClose={this.props.handleClose} setValues={this.props.setValues} cardErrors={this.state.cardErrors} setErrors={this.setErrors} handleCardNumberChange={this.handleCardNumberChange} handleError={this.handleError} />
                            </Elements>

                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>)
    }
}