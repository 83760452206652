import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export const baseURLconfig = require("../../../framework/src/config");

export interface FileInterface {
  id: number;
  name: string;
  downloaded_at: string | null;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loader: boolean;
  progress: number;
  dowloadId: number;
  files: FileInterface[];
  downloadJobID: number;
  reference_id: number;
  reference_type: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  downloadListApiID: string = "";
  downloadFileApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: this.props.navigation.state?.params?.token ?? "",
      loader: false,
      progress: 0,
      dowloadId: 0,
      files: [],
      downloadJobID: 0,
      reference_id: this.props.navigation.state?.params?.reference_id ?? "",
      reference_type: this.props.navigation.state?.params?.reference_type ?? "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getFileList = this.getFileList.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.downloadFileApiID) {
        if (responseJson && !responseJson.error && !responseJson.errors) {
          let files: FileInterface[] = responseJson.downloadable.data
            .attributes.files as FileInterface[];
          this.setState({
            files: files.reverse(),
            loader: false,
          });
        }
      
        if (responseJson?.error || errorJson || responseJson.errors) {
          this.setState({
            loader: false,
          });
          this.showAlert(
            configJSON.errorAlertHeading,
            configJSON.listErrorAlertText
          );
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getFileList = async () => {
    this.setState({
      loader: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.downloadListApiID = requestMessage.messageId;

    const convertHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Token: this.state.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListFileAPIEndpoint}${configJSON.refrenceIDText}${this.state.reference_id}${configJSON.refrenceTypeText}${this.state.reference_type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(convertHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationGETApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getFileList();
  }
  // Customizable Area End
}
