import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { Box, Dialog } from "@mui/material";
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';

function GradientCircularProgress(props: CircularProgressProps) {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={(theme) => ({
                    color: theme.palette.grey[200],
                    ...theme.applyStyles('dark', {
                        color: theme.palette.grey[800],
                    }),
                })}
                size={70}
                thickness={6}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={(theme) => ({
                    color: '#FBBF24',
                    animationDuration: '1000ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                        strokeDasharray: '15, 200px',
                        strokeDashoffset: '0px',
                    },
                    ...theme.applyStyles('dark', {
                        color: '#308fe8',
                    }),
                })}
                size={70}
                thickness={6}
                {...props}
            />
        </Box>
    );
}

const CircularProgressDialog = ({ handleClose, processPayment }: { handleClose: any, processPayment: any }) => {
    useEffect(() => {
        processPayment()
    })
    return (
        <Dialog open={true} sx={{
            ".MuiDialog-paperWidthSm": {
                borderRadius: "12px",
                overflow: "hidden",
            }
        }}>
            <Box sx={{ backgroundColor: '#ffffff', width: { sm: '500px', xs: '200px' }, height: '250px', borderRadius: '20px', padding: '10px' }}>
                <IconButton
                    aria-label="close"
                    data-testid="close-button"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: theme.palette.grey[500],
                    })}
                    onClick={() => handleClose("payment-processed", false)}
                >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.72504 0.283505C9.58492 0.143065 9.39468 0.0641406 9.19629 0.0641406C8.9979 0.0641406 8.80766 0.143065 8.66754 0.283505L5.00004 3.9435L1.33254 0.276005C1.19242 0.135565 1.00218 0.0566406 0.803789 0.0566406C0.605401 0.0566406 0.415163 0.135565 0.275039 0.276005C-0.0174609 0.568505 -0.0174609 1.041 0.275039 1.3335L3.94254 5.001L0.275039 8.6685C-0.0174609 8.961 -0.0174609 9.4335 0.275039 9.726C0.567539 10.0185 1.04004 10.0185 1.33254 9.726L5.00004 6.0585L8.66754 9.726C8.96004 10.0185 9.43254 10.0185 9.72504 9.726C10.0175 9.4335 10.0175 8.961 9.72504 8.6685L6.05754 5.001L9.72504 1.3335C10.01 1.0485 10.01 0.568505 9.72504 0.283505Z" fill="#0F172A" />
                    </svg>
                </IconButton>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%" }} >
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}> <GradientCircularProgress /></div>
                        <p style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 700, color: '#94A3B8' }}>Payment is being processed</p>
                    </div>
                </Box>
            </Box>
        </Dialog>
    )
}

export default CircularProgressDialog