import React from 'react'
import { Button, Stack, Box } from "@mui/material"
import { Action } from '../CustomisableUserProfilesController';

interface Props {
    disabled: boolean;
    onSaveNewDependant: any;
    userProfileObj: any;
    onCancelClick: any;
    onEditClick: any;
    saveUserProfile: any;
    btnTxt: string;
    isVisible: string;
    profileAction: Action;
}

const HeaderBtnGroup = ({ profileAction, isVisible, disabled, onSaveNewDependant, userProfileObj, onCancelClick, onEditClick, saveUserProfile, btnTxt }: Props) => {
    const isCancel = (profileAction === Action.EDIT_PROFILE || profileAction === Action.CREATE_DEPENDANT || profileAction === Action.EDIT_DEPENDANT);
    const isEdit = (profileAction === Action.GET_PROFILE);
    const isSave = (profileAction === Action.EDIT_PROFILE || profileAction === Action.EDIT_DEPENDANT);
    const isFinish = (profileAction === Action.CREATE_DEPENDANT);
    return (
        <Box className={`${isVisible}`}>
            <Stack className={`btn-group`} direction={"row"} gap={"20px"}>
                {isCancel && <Button className="cancel-btn btn" onClick={() => onCancelClick()}>{userProfileObj.cancelTxt}</Button>}
                {isEdit && <Button className={`${disabled ? "disabled" : "edit-btn"} btn`} onClick={() => onEditClick()}>{btnTxt}</Button>}
                {isSave && <Button className={`${disabled ? "disabled" : "edit-btn"} btn`} onClick={() => profileAction === Action.EDIT_DEPENDANT ? onSaveNewDependant() : saveUserProfile()} disabled={disabled}>{btnTxt}</Button>}
                {isFinish && <Button className={`${disabled ? "disabled" : "finish-btn"} btn`} disabled={disabled} onClick={onSaveNewDependant}>{userProfileObj.finishTxt}</Button>}
            </Stack>
        </Box>
    )
}

export default HeaderBtnGroup