Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.getSubscriptionPlanApiMethod = "GET";
exports.getSubscriptionPlanApiEndPoint = "/bx_block_subscription_billing/subscription_plan/show_active_plan";
exports.btnExampleTitle = "CLICK ME";
exports.STRIPE_PUBLISHABLE_KEY = "pk_test_51PdGE62MBo87uMMSFTEc6xlR72AULNbdDfZ22iAwrqAF0ePUSMifYVV4JpcT47Ko4A8I15fsjgeqAhboHqiHco2H00iLO5LIdO";
exports.paymentProcessingEndPoint = "/bx_block_subscription_billing/subscription";
// Customizable Area End