import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const {Theme} = require("../theme");
const { ModalBody, style } = Theme;
import CloseButton from "./CloseButton";

interface Props {
    open: boolean;
    onClose: any;
}

export default function DependantAlert({ open, onClose }: Props) {

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBody sx={style}>
                <CloseButton onClose={onClose} />
                <Stack alignItems={"center"} justifyContent={"center"}>
                    <Stack justifyContent={"center"} alignItems={"center"} gap={"24px"} marginTop={"1rem"}>
                        <svg width="54" height="53" viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" width="53" height="53" rx="26.5" fill="#D6D3D1" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.543 26.4996C16.543 20.7254 21.2188 16.0391 26.993 16.0391C32.7777 16.0391 37.464 20.7254 37.464 26.4996C37.464 32.2738 32.7777 36.9601 26.993 36.9601C21.2188 36.9601 16.543 32.2738 16.543 26.4996ZM28.0495 22.3154C28.0495 21.74 27.5788 21.2693 27.0035 21.2693C26.4282 21.2693 25.9574 21.74 25.9574 22.3154V26.4996C25.9574 27.0749 26.4282 27.5456 27.0035 27.5456C27.5788 27.5456 28.0495 27.0749 28.0495 26.4996V22.3154ZM27.0035 34.868C22.3799 34.868 18.6351 31.1231 18.6351 26.4996C18.6351 21.876 22.3799 18.1312 27.0035 18.1312C31.627 18.1312 35.3719 21.876 35.3719 26.4996C35.3719 31.1231 31.627 34.868 27.0035 34.868ZM25.9574 29.6377V31.7299H28.0495V29.6377H25.9574Z" fill="white" />
                        </svg>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#334155', textAlign: 'center', textTransform: "capitalize" }}>
                            The maximum number of  dependants <span style={{ display: "block" }}>has been reached</span>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ m: 0 }} style={{ color: "#64748B", fontWeight: 700, fontSize: "14px" }}>
                            The maximum number of dependants is 7. Please remove a <span style={{ display: "block" }}>dependant to add the new one.</span>
                        </Typography>
                    </Stack>
                </Stack>
            </ModalBody>
        </Modal>
    );
}
