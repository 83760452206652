import React from 'react'
import { Stack } from '@mui/joy'
import { Typography, Button } from '@mui/material'
import { styles } from "../ExistingMemberRegistration.web"

const ExistingMembetNotExists = ({redirectToSignIn}: any) => {
  return (
    <Stack width={"541px"} alignItems={"center"} gap={"30px"}>
      <Stack gap={"24px"} alignItems={"center"}>
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="76" height="76" rx="38" fill="#D6D3D1" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23 38C23 29.72 29.705 23 37.985 23C46.28 23 53 29.72 53 38C53 46.28 46.28 53 37.985 53C29.705 53 23 46.28 23 38ZM39.5 32C39.5 31.175 38.825 30.5 38 30.5C37.175 30.5 36.5 31.175 36.5 32V38C36.5 38.825 37.175 39.5 38 39.5C38.825 39.5 39.5 38.825 39.5 38V32ZM38 50C31.37 50 26 44.63 26 38C26 31.37 31.37 26 38 26C44.63 26 50 31.37 50 38C50 44.63 44.63 50 38 50ZM36.5 42.5V45.5H39.5V42.5H36.5Z" fill="white" />
        </svg>
        <Stack gap={"10px"}>
          <Typography sx={{ color: "#1E293B", fontFamily: "Inter", fontWeight: 400, fontSize: "30px", textAlign: "center" }}>Account with this email was not found</Typography>
          <Typography sx={{ color: "#94A3B8", fontFamily: "Inter", fontWeight: 400, fontSize: "18px", textAlign: "center" }}>We couldn't find an account associated with this email address, contact your Advantage PHC representative for help</Typography>
        </Stack>
      </Stack>
      <Button
        data-test-id="signUpButton"
        onClick={() => redirectToSignIn("Home")}
        variant="contained" style={{ ...styles.signup, textTransform: "none", width: "104px", height: "44px" }} >
        Go back
      </Button>
    </Stack>
  )
}

export default ExistingMembetNotExists