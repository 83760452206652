import React from 'react'
import { styled, Typography, Stack } from "@mui/material"
const { userProfileObj } = require("../config");
import HeaderbtnGroup from "./HeaderBtnGroup"
import { Action } from '../CustomisableUserProfilesController';

interface Props {
  btnTxt: string;
  userName: string;
  userAbbr: string;
  onEditClick: any;
  onCancelClick: any;
  saveUserProfile: any;
  disabled: boolean;
  onSaveNewDependant: any;
  profileAction: Action;
}

const Header = ({ profileAction, btnTxt, userAbbr, userName, onCancelClick, onEditClick, saveUserProfile, disabled, onSaveNewDependant }: Props) => {
  return (
    <CustomHeader>
      <div className="main-header">
        <Stack direction={"row"} alignItems={"center"}>
          <Stack justifyContent={"center"} alignItems={"center"} className='circle'>
            <Stack justifyContent={"center"} alignItems={"center"} className='user-profile'>
              <Typography className='user-abbr'>{userAbbr}</Typography>
            </Stack>
          </Stack>
          <Typography component={"h1"} className='user-name'>{userName}</Typography>
        </Stack>
        <HeaderbtnGroup
          isVisible={"visible"}
          disabled={disabled}
          onSaveNewDependant={onSaveNewDependant}
          userProfileObj={userProfileObj}
          onCancelClick={onCancelClick}
          onEditClick={onEditClick}
          saveUserProfile={saveUserProfile}
          btnTxt={btnTxt} 
          profileAction={profileAction} />
      </div>
    </CustomHeader>
  )
}

const CustomHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "calc(100% - 100px)",
  height: "193px",

  "& .circle": {
    width: "207px", height: "193px", borderRadius: "100px", background: "#F1F5F9"
  },
  "& .user-profile": {
    width: "121px",
    height: "121px",
    padding: "18px",
    borderRadius: "100px",
    backgroundColor: "#5DDB62",
    "& .user-abbr": {
      fontFamily: "Inter",
      fontSize: "64px",
      color: "#fff",
      fontWeight: 400,
      textTransform: "uppercase"
    },
    "@media(max-width: 1140px)": {
      ".user-abbr": {
        fontSize: "50px",
      },
    },
    "@media(max-width: 739px)": {
      ".user-abbr": {
        fontSize: "40px",
      },
    },
  },
  "& .main-header": {
    width: "100%",
    height: "133px",
    background: "#F1F5F9",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 28px 0 12px"
  },
  "& .disabled": {
    background: "transparent",
    color: "#64748B"
  },
  "& .user-name": {
    fontWeight: 400,
    color: "#1E293B",
    fontSize: "42px",
    textTransform: "capitalize",
    fontFamily: "Inter"
  },
  "@media(max-width: 1140px)": {
    ".main-header": {
      height: "114px",
    },
    ".user-name": {
      fontSize: "32px"
    },
    ".user-profile": {
      width: "110px",
      height: "110px"
    },
    ".circle": {
      width: "177px",
      height: "163px"
    }
  },
  "@media(max-width: 920px)": {
    width: "100%"
  },
  "@media(max-width: 739px)": {
    width: "100%",
    ".main-header": {
      height: "70px",
      padding: 0
    },
    ".circle": {
      width: "120px",
      height: "120px"
    },
    ".user-profile": {
      width: "90px",
      height: "90px"
    },
    ".user-name": {
      fontSize: "28px"
    },
  }
})

export default Header