import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseButton from "./CloseButton";
const {Theme} = require("../theme");
const { ModalBody, style } = Theme;

interface Props {
    open: boolean;
    onClose: any;
    onClickDelete: any;
}

export default function DeleteDependantModal({ open, onClose, onClickDelete }: Props) {

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBody sx={style}>
                <CloseButton onClose={onClose} />
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} gap={"64px"} marginTop={"1rem"}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{color: '#DC2626'}}>
                            Dependant Deleting
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete this dependent?
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} gap={"28px"} width={"100%"}>
                        <Button className='btn edit-btn' onClick={() => onClose()}>Cancel</Button>
                        <Button className='btn cancel-btn' onClick={() => onClickDelete()} style={{color: "#F87171"}}>Delete</Button>
                    </Stack>
                </Stack>
            </ModalBody>
        </Modal>
    );
}
