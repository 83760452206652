export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const bgImage = require("../assets/bgimage.png");
export const sidePic = require("../assets/sidePic.png");
export const logo = require("../assets/logo.png");
export const pendingSide = require("../assets/pendingSide.png").default;
export const errorOutline = require("../assets/errorOutline.png").default;

