import React from 'react'
import { Stack } from '@mui/joy'
import { Typography, Button } from '@mui/material'
import { styles } from '../ExistingMemberRegistration.web'
import { headLogo } from '../assets'

const ExistingMemberExists = ({backTo, startVerification}: {backTo: any, startVerification: any}) => {
    return (
        <Stack sx={{...styles.form, width: "520px", margin: "0 !important"}} direction={"row"} gap={"30px"}>
            <Stack alignItems={"center"} style={{...styles.headImg, margin: 0}}>
                <img src={headLogo.default} />
            </Stack>
            <Stack justifyContent={"center"}>
                <Typography variant="h4" component="h1" style={styles.head1}>
                    Welcome
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h5" component="h2" style={styles.head2}>
                        To
                    </Typography>
                    <Typography variant="h5" component="h2" style={styles.head3}>
                        Advantage PHC
                    </Typography>
                </div>
            </Stack>
            <Stack gap={"50px"}>
                <Typography sx={{
                    color: "#0F172A",
                    fontWeight: 700,
                    fontFamily: "Inter",
                    paddingRight: "5rem"
                }}>There is an existing Advantage PHC account associated with this email, please verify your phone number and email</Typography>
                <Stack>
                    <Button
                        data-test-id="signUpButton"
                        variant="contained" style={{ ...styles.signup, textTransform: "none" }}
                        onClick={() => startVerification()}
                    >
                        Start verification
                    </Button>
                    <Button
                        data-test-id="signUpButton"
                        variant="contained" style={{ ...styles.cancelBtn, textTransform: "none" }} 
                        onClick={() => backTo()}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ExistingMemberExists