export const icHome = require("../assets/ic_home.png");
export const icContactUs = require("../assets/ic_contact_us.svg");
export const icDashboard = require("../assets/ic_dashboard.svg");
export const icHealthWallet = require("../assets/ic_health_wallet.svg");
export const icMyCases = require("../assets/ic_mycases.svg");
export const icSetting = require("../assets/ic_setting.svg");
export const img1 = require("../assets/img1.png");
export const img2 = require("../assets/img2.png");
export const img3 = require("../assets/img3.png");
export const newActivity = require('../assets/newactivity.svg');
export const prevActivity = require('../assets/prevactivity.svg');
export const successIcon = require('../assets/twotone-success.png');
export const warningIcon = require('../assets/WarningImage.png');