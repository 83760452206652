// Customizable Area Start
import React from "react";
import EmailAccountRegistrationController, { Props, Verification, configJSON, menuItem } from "./EmailAccountRegistrationController";
import { styles as style } from "./ExistingMemberRegistration.web";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OtpInput from 'react-otp-input';
import { styled } from '@mui/material/styles';
import Timer from "../../../blocks/customisableuserprofiles/src/components/Timer";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface CodeProps {
    verificationTxt: string;
    verificationCodeTxt: string;
    validTxt: string;
}

export default class OTPInputAuth extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() {
        const user = JSON.parse(sessionStorage.getItem("user") || "");
        this.setState({email: user.email, phone: user.phoneNumber})
    }
    render() {
        const getOTPCodeBox = ({ verificationTxt, verificationCodeTxt, validTxt }: CodeProps) => {
            return (
                <>
                    <Grid item xs={12}>
                        <Typography sx={styles.phone}>{verificationTxt}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={styles.verification}>
                            {verificationCodeTxt}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={styles.valid}>{validTxt}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={styles.phoneNumber}>{this.state.verificationType === Verification.PHONE ? this.formatPhoneString(this.state.phone) : this.state.email}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.otpBox}>
                            <OtpInput
                                value={this.state.otp}
                                inputType="tel"
                                onChange={(value) => this.handleOtp(value)}
                                containerStyle={{ outline: "none" }}
                                data-test-id="emailOtpInput"
                                numInputs={4}
                                renderInput={(props) => (
                                    <input
                                        {...props}
                                        placeholder="0"
                                        style={{
                                            ...props.style,
                                            borderColor: this.state.otpError ? "red" : "#CBD5E1",
                                            display: "flex",
                                            width: "56px",
                                            height: "56px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "1px solid #CBD5E1",
                                            borderRadius: "8px",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            fontFamily: "Inter",
                                            padding: "0",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            margin: "8px",
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        {this.state.otpError ? (
                            <Box sx={styles.otpError}>{this.state.otpErrorMessages.otpValidationError}</Box>
                        ) : null}
                        {this.state.resendOtpSent ? (
                            <Box sx={{ ...styles.otpError, color: "#047857" }}>{this.state.otpErrorMessages.codeSentSuccess}</Box>
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Timer styles={{ resend: styles.resend, resendBtn: styles.resendBtn, time: styles.time, resendRedBtn: styles.resendRedBtn, btn: styles.btn }} onResenCode={this.handleResendCode} />
                    </Grid>
                    <Grid item xs={12} style={styles.grid2}>
                        <Button
                            variant="contained"
                            sx={styles.Btn}
                            data-test-id="emailVerify"
                            onClick={() => this.handleOtpSubmit()}
                        >
                            Continue
                        </Button>
                    </Grid>
                </>
            );
        };
        const getScreen = (screen: "verify" | "success" | "salesforce") => {
            if (screen === "verify") {
                return (
                    <>
                        <div style={styles.Hero} data-test-id="backButton" onClick={() => this.redirectTo("verify")}>
                            <ArrowBackIosIcon style={styles.backarrow} />
                            <div style={styles.BacK}>Back</div>
                        </div>
                        <Grid container sx={styles.otpContainer}>
                            <Grid item xs={12} style={styles.grid}>
                                <div style={styles.headImg}>
                                    <img src={this.state.head} />
                                </div>
                            </Grid>
                            {(this.state.verificationType === Verification.EMAIL) ?
                                getOTPCodeBox({ verificationTxt: configJSON.emailVerificatioTxt, verificationCodeTxt: configJSON.verificationEmailCodeTxt, validTxt: configJSON.emailValidTxt }) : getOTPCodeBox({ verificationTxt: configJSON.phoneVerificationTxt, verificationCodeTxt: configJSON.verificationPhoneCodeTxt, validTxt: configJSON.smsValidTxt })}
                        </Grid>
                    </>
                )
            } else if (screen === 'success') {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={styles.Hero} data-test-id="backBtn"></div>
                            <Grid container sx={styles.successContainer}>
                                <Grid item xs={12} style={styles.grid}>
                                    <div style={styles.successheadImg}>
                                        <img src={this.state.head} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={styles.successBox}>
                                    <Typography sx={styles.success}>
                                        We have successfully linked
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.success}>
                                        your account
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.signingIn}>
                                        Signing you in
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            } else if (screen === "salesforce") {
                return (
                    <Grid container>
                        <div style={style.root} onClick={() => this.redirectTo("salesforce")} >
                            <ArrowBackIosIcon style={style.arrow} />
                            <div style={style.backBtn}>Back</div>
                        </div>
                        <Grid item xs={12} height={"100%"}>
                            <Stack justifyContent={"center"} gap={"20px"} sx={{ mt: "3rem", mb: "1rem" }}>
                                <Stack sx={{
                                    ...style.form,
                                    marginLeft: { xs: '1rem', md: '70px', lg: '4.5rem' },
                                }} direction={"row"} gap={"18px"}>
                                    <div style={{ ...style.headImg, width: "70px", height: "80px", margin: 0 }}>
                                        <img src={this.state.head} />
                                    </div>
                                    <div style={style.headContent}>
                                        <Typography variant="h4" component="h1" style={style.head1}>
                                            Welcome
                                        </Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant="h5" component="h2" style={style.head2}>
                                                To
                                            </Typography>
                                            <Typography variant="h5" component="h2" style={style.head3}>
                                                Advantage PHC
                                            </Typography>
                                        </div>
                                    </div>
                                </Stack>
                            </Stack>
                            <Stack gap={"30px"}
                                sx={{
                                    width: { md: "100%" },
                                    boxSizing: "border-box",
                                    padding: { xs: '0 1rem 0 1rem', md: '0 70px 0 70px', lg: '0 4.5rem 0 4.5rem' }
                                }}>
                                <Typography sx={{
                                    color: "#0F172A",
                                    fontWeight: 700,
                                    fontFamily: "Inter",
                                    fontSize: { xs: "16px", lg: "20px" },
                                    width: { xs: "100%", lg: "534px" },
                                }}>We found more than one account associated with this email address, please select a role for each person:
                                </Typography>
                                <Stack>
                                    {this.state.salesforce_accounts.map((item, index) => (
                                        <Box>
                                            <Stack
                                                direction={"row"}
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                                key={item.Id} sx={{
                                                    maxHeight: "118px",
                                                    boxSizing: "border-box",
                                                    border: "1px solid",
                                                    borderColor: item.borderColor,
                                                    borderRadius: "12px",
                                                    padding: { xs: "18px", lg: "18px 32px" },
                                                }}>
                                                <Stack gap={"6px"}>
                                                    <Typography sx={{
                                                        color: "#000",
                                                        fontWeight: 400,
                                                        fontFamily: "Inter",
                                                        fontSize: "14px"
                                                    }}>{`Person ${index + 1}`}</Typography>
                                                    <Typography sx={{
                                                        color: "#000",
                                                        fontWeight: 700,
                                                        fontFamily: "Inter",
                                                        fontSize: { xs: "18px", lg: "24px" },
                                                    }}>{`${item.FirstName || ""} ${item.LastName || ""}`}</Typography>
                                                </Stack>
                                                <Stack gap={"6px"}>
                                                    <Stack gap={"4px"}>
                                                        <Typography sx={{
                                                            color: "#000",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                            lineHeight: "24px",
                                                            letterSpacing: 0,
                                                        }}>{"Relationship to Insured"}</Typography>
                                                        <Select
                                                            name={"submitting_for"}
                                                            displayEmpty
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            sx={{
                                                                borderRadius: '8px',
                                                                width: "180px",
                                                                height: "56px",

                                                                ".MuiOutlinedInput-notchedOutline": {
                                                                    border: "1px solid #CBD5E1",
                                                                    padding: "0 !important",
                                                                    borderRadius: "8px",
                                                                },
                                                                ".MuiSelect-iconOutlined": {
                                                                    color: "#1E293B"
                                                                },
                                                                ".Mui-selected": {
                                                                    background: "#FFFBEB"
                                                                },
                                                            }}
                                                            renderValue={(selected) => {                     
                                                              if (selected.length === 0) {
                                                                return <span>Select option</span>;
                                                              }
                                                              return selected ;
                                                            }}
                                                            value={item.relationShip}
                                                            onChange={(e) => this.handleSelectInput(item.Id, e.target.value)}
                                                        >
                                                            {menuItem.map((item, index) => <MenuItem key={index} value={item.value} style={{ color: "#0F172A", fontSize: "16px", fontWeight: 400 }}>{item.label}</MenuItem>)}
                                                        </Select>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            {<Box sx={{
                                                    color: '#DC2626',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    fontFamily: "Inter",
                                                    padding: "8px",
                                                    height: "16px"
                                                }}>{item.error ? item.error_message: ""}</Box>}
                                        </Box>
                                    ))}
                                    <Typography sx={{
                                        color: "#475569",
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                        fontWeight: 400
                                    }}>*You will be able to add more dependants later</Typography>
                                </Stack>
                                <Stack gap={"10px"}>
                                    <Button
                                        data-test-id="signUpButton"
                                        variant="contained" style={{ ...style.signup, textTransform: "none" }}
                                        onClick={this.handleSubmitRoles}
                                    >
                                        Confirm roles
                                    </Button>
                                    <Button
                                        data-test-id="cancelButton"
                                        variant="contained" style={{ ...style.cancelBtn, textTransform: "none" }}
                                        onClick={() => this.setState({verificationType: Verification.EMAIL, screen: "verify"})}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
        }
        return (
            <Grid container style={styles.FontFam}>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            {getScreen(this.state.screen)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.imgBox}>
                        <CustomImg src={this.state.sidePic2} />
                    </Box>
                </Grid>
            </Grid>
        )
    }
}

const CustomImg = styled('img')(({ theme }) => ({
    width: '100%',
    marginTop: '42px',
    marginBottom: '40px',
    height: '100%',
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
        borderRadius: '64px 0px 0px 64px',
        height: '73%'
    },
    [theme.breakpoints.up('xs')]: {
        marginBottom: 0
    },
}));
const styles = {
    Hero: {
        alignItems: 'center',
        display: 'flex',
        width: '71px',
        height: "26px",
        marginTop: '58px',
        marginLeft: '34px'
    },
    backarrow: {
        color: '#0F172A',
        cursor: "pointer"
    },
    BacK: {
        color: '#0F172A',
        fontSize: '18px',
        fontFamily: "Inter"
    },
    otpContainer: {
        alignItems: 'center', margin: '120px 0 0 0',
        padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' }, display: 'flex',
        width: '100%'
    },
    grid: {
        justifyContent: 'center',
        display: 'flex'
    },
    headImg: {
        width: '100px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center'
    },
    phone: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        margin: '17px 0px 10px 0px',
        textAlign: 'center',
        fontWeight: '400',
        fontFamily: "Inter",
        fontSize: '24px'
    },
    verification: {
        display: 'flex',
        textAlign: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        fontSize: '14px',
        width: '100%',
        margin: '3px 0px',
        fontWeight: '400',
        fontFamily: "Inter"
    },
    valid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        color: '#64748B',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: "Inter",
        margin: '3px 0px'
    },
    signingIn: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        color: '#64748B',
        fontSize: '20px',
        fontWeight: '400',
        fontFamily: "Inter",
        margin: '15px 0px'
    },
    phoneNumber: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        fontWeight: '400',
        fontFamily: "Inter",
        fontSize: '20px',
        margin: '30px 0px'
    },
    otpBox: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        fontFamily: "Inter"
    },
    otpInput: {

    },
    otpError: {
        fontWeight: '400',
        fontFamily: "Inter",
        color: '#DC2626',
        width: '100%',
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'center'
    },
    resend: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        fontSize: '14px',
        width: '100%',
        margin: '2rem 0 1rem 0',
        fontWeight: '600',
        fontFamily: "Inter"
    },
    resendRedBtn: {
        color: '#D97706',
    },
    btn: {
        marginRight: '4px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 600,
        padding: 0,
        textTransform: "none"
    },
    resendBtn: {
        color: '#A8A29E',
    },
    time: {
        color: '#0F172A',
        fontFamily: "Inter"
    },
    Btn: {
        height: '56px',
        width: '100%',
        background: 'linear-gradient(to right, #FCD34D, #D97706 )',
        fontSize: '15px',
        fontFamily: "Inter"
    },
    grid2: {
        width: '100%'
    },
    imgBox: {
        marginTop: { xs: 0, lg: '20px' },
        display: 'flex',
        justifyContent: 'center',
        width: "100%"
    },
    success: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        color: "#059669",
        fontSize: "30px",
        fontWeight: "400",
        fontFamily: "Inter"
    },
    successBox: {
        marginTop: '20px'
    },
    successContainer: {
        display: "flex",
        alignItems: 'center',
        margin: { xs: '100px 0 100px 0', md: '200px 0 0 0', lg: '200px 0 0 0' },
        justifyContent: 'center',
        padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' },
        width: '100%',
    },
    successheadImg: {
        width: '120px',
        height: '120px',
        display: 'flex',
        justifyContent: 'center'
    },
    successHero: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '71px',
        height: "26px",
        marginTop: '58px',
        marginLeft: '34px',

    },
    FontFam: {
        fontFamily: "Inter"
    }
};

// Customizable Area End
