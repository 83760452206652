export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const imgPhcPic = require("../assets/phcPic.png");
export const successSvg = require("../assets/Vector.png")
export const visaCard = require("../assets/visacard.png");
export const amexCard = require("../assets/Frame260.png");
export const masterCard = require("../assets/masterCard.png");
export const validCard = require("../assets/validCard.png");
export const expireCard = require("../assets/expireCard.png");