import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseButton from "./CloseButton";
const {Theme} = require("../theme");
const { ModalBody, style } = Theme;

interface Props {
  heading: string;
  title: string;
  btnTitle1: string;
  btnTitle2: string;
  open: boolean;
  onClose: any;
  onClickVerify: any;
}

export default function EmailPhoneVerificationModal({ heading, title, btnTitle1, btnTitle2, open, onClose, onClickVerify }: Props) {

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBody sx={style}>
        <CloseButton onClose={onClose} />
        <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} gap={"64px"} marginTop={"1rem"}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {heading}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, width: "412px" }}>
              {title}
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"28px"} width={"100%"} className='modal-btn-group'>
            <Button className='btn edit-btn' onClick={() => onClickVerify()}>{btnTitle1}</Button>
            <Button className='btn cancel-btn' onClick={() => onClose()}>{btnTitle2}</Button>
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  );
}
