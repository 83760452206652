import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
const { Theme } = require("../theme");
const { userProfileObj } = require("../config");
const { ModalBody, style } = Theme;
import CloseButton from "./CloseButton";

interface Props {
    open: boolean;
    description: string;
    onSubmit: any;
}

const VerificationSuccessModal = ({ open, description, onSubmit }: Props) => {
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBody sx={style}>
                <CloseButton onClose={() => onSubmit("CustomisableUserProfiles")} />
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} gap={"64px"} marginTop={"1rem"}>
                    <Stack alignItems={"center"} justifyContent={"center"} gap={"18px"}>
                        <svg width="38" height="29" viewBox="0 0 38 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7554 23.4669L3.83159 15.4086C3.62438 15.1953 3.37761 15.026 3.10557 14.9104C2.83354 14.7948 2.54165 14.7353 2.24683 14.7353C1.952 14.7353 1.66011 14.7948 1.38808 14.9104C1.11604 15.026 0.869269 15.1953 0.662066 15.4086C0.452335 15.6193 0.285819 15.8703 0.17217 16.1469C0.0585208 16.4236 0 16.7204 0 17.0203C0 17.3201 0.0585208 17.6169 0.17217 17.8936C0.285819 18.1702 0.452335 18.4212 0.662066 18.6319L10.148 28.2789C11.0309 29.1769 12.4572 29.1769 13.3401 28.2789L37.3379 3.89665C37.5477 3.68593 37.7142 3.43497 37.8278 3.15831C37.9415 2.88165 38 2.58481 38 2.28498C38 1.98515 37.9415 1.6883 37.8278 1.41165C37.7142 1.13499 37.5477 0.88403 37.3379 0.673309C37.1307 0.460016 36.884 0.290672 36.6119 0.175093C36.3399 0.059514 36.048 0 35.7532 0C35.4584 0 35.1665 0.059514 34.8944 0.175093C34.6224 0.290672 34.3756 0.460016 34.1684 0.673309L11.7554 23.4669Z" fill="#059669" />
                        </svg>
                        <Stack justifyContent={"center"} alignItems={"center"} gap={"8px"}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#334155', textAlign: 'center', textTransform: "capitalize" }}>
                                {userProfileObj.dataVerificationTxt}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ m: 0 }} style={{ color: "#64748B", fontWeight: 700, fontSize: "14px" }}>
                                {description}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"} gap={"28px"} width={"100%"} className='modal-btn-group'>
                        <Button className='btn edit-btn' onClick={() => onSubmit("CustomisableUserProfiles")}>{userProfileObj.backToProfileTxt}</Button>
                    </Stack>
                </Stack>
            </ModalBody>
        </Modal>
    );
}

export default VerificationSuccessModal