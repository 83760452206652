// Customizable Area Start
import React from "react";
import Subscriptionbilling2Controller, {
  Props,
} from "./Subscriptionbilling2Controller";
import { Grid, TextField, Button, Typography, Box, InputLabel } from '@mui/material';
import { Formik, Form , FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import Layout from "../../landingpage/src/Layout.web";
import { masterCard, visaCard, FrameCard } from './assets';
import {Elements,ElementsConsumer,CardCvcElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js';

interface AddCaseTouched {
  firstName: boolean,
  lastName: boolean,
  addressLine1: boolean,
  addressLine2: boolean,
  city: boolean,
  state: boolean,
  zipCode: boolean,
}

interface AddCaseError {
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zipCode: string,
}

export const initialValues : any = {
  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  addressLine1: Yup.string().required('Address Line 1 is required'),
  addressLine2: Yup.string().required('Address Line 2 is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('ZIP Code is required'),
});

export default class AddSubscription extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);

  }

  getErrorMessage = (
    touched: FormikTouched<AddCaseTouched>,
    errors: FormikErrors<AddCaseError>,
    value: string
  ) => {
    return (
      touched[value as keyof AddCaseTouched] &&
      errors[value as keyof AddCaseError] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "12px", color: "#DC2626", fontWeight: 400 }}
        >
          {errors[value as keyof AddCaseTouched]}
        </Typography>
      )
    );
  };

  render() {
    return (
      <Layout id="" navigation={this.props.navigation}>
        <Box sx={{ margin: 'auto', padding: '20px', fontFamily: 'Inter' }}>
          <Typography variant="h5" sx={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: 700 }} gutterBottom>
            Subscription Billing
          </Typography>
          <Typography variant="h6" sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 700, color: '#475569', paddingTop: '40px' }}>Payment Information</Typography>

          <Elements stripe={this.stripePromise} options={this.options} data-test-id="stripeElement">
            <ElementsConsumer data-test-id="stripeElementConsumer">
              {({ stripe, elements }) => {
                return (
                <Formik
                  data-test-id='formik'
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    this.handleSubmit(values,stripe,elements)
                    // this.props.navigation.navigate('PaymentConfirmation')
                  }}
                >
                  {({ handleChange, values, handleBlur, touched, errors, handleSubmit }) => (
                    <Form data-test-id="subscription-form" onSubmit={handleSubmit}>
                      <Box sx={{ paddingLeft: { sx: '0px', sm: '30px' } }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} style={{ marginTop: '15px' }}>
                            <Typography variant="h6" sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: '#64748B' }}>Personal Data</Typography>
                          </Grid>
                          <Grid item xs={6} sm={3} style={{ paddingTop: '15px' }}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="firstName">
                              First Name
                            </InputLabel>
                            <TextField
                              data-test-id="firstName"
                              name="firstName"
                              placeholder="Enter First Name"
                              error={touched.firstName && Boolean(errors.firstName)}
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ marginTop: '6px' }}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  height: "8px",
                                  fontFamily: 'Inter',
                                  outline: 'none',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                },
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "firstName")}
                          </Grid>
                          <Grid item xs={6} sm={3} style={{ paddingTop: '15px' }}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="lastName">
                              Last Name
                            </InputLabel>
                            <TextField
                              data-test-id="lastName"
                              name="lastName"
                              placeholder="Enter Last Name"
                              error={touched.lastName && Boolean(errors.lastName)}
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ marginTop: '6px' }}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  height: "8px",
                                  fontFamily: 'Inter',
                                  outline: 'none',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                },
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "lastName")}
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h6" sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: '#64748B' }}>Card Information</Typography>
                          </Grid>
                          <Grid style={{ paddingTop: '15px' }} item xs={12} sm={3}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="cardNumber">
                              Card Number
                            </InputLabel>
                              <Box
                                data-testid="card-number-element"
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                sx={{
                                  border: this.handleErrorColor(this.state.cardErrors.cardNumberError ,"1px solid #DC2626" ,"1px solid #CBD5E1"),
                                  borderRadius: "8px",
                                  padding: "10px 8px",
                                  height: "56px",
                                  boxSizing: "border-box"
                                }}
                              >
                                <CardNumberElement
                                  onChange={this.handleCardNumberChange}
                                  data-test-id="card-number-element"
                                  options={{
                                    placeholder: "0000-0000-0000-0000",
                                    style: {
                                      base: {
                                        fontSize: "15px",
                                        color: this.handleErrorColor(this.state.cardErrors.cardNumberError ,"#DC2626" , "#2b2b2b"),
                                        '::placeholder': {
                                          color: '#a2a2a2',
                                        },
                                      },
                                      invalid: {
                                        color: "#DC2626"
                                      },
                                    }
                                  }} />
                              </Box>
                              {this.state.cardErrors.cardNumberError && <Typography sx={{
                                color: "#DC2626",
                                fontSize: "12px",
                                fontFamily: "Inter",
                                fontWeight: 400
                              }}>{this.state.cardErrors.cardNumberError}</Typography>}
                          </Grid>
                          <Grid style={{ paddingTop: '15px' }} item xs={6} sm={2}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="expirationDate">
                              Expiration Date
                            </InputLabel>
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                sx={{
                                  border: this.handleErrorColor(this.state.cardErrors.cardExpiryError , "1px solid #DC2626" ,"1px solid #CBD5E1"),
                                  borderRadius: "8px",
                                  padding: "10px 8px",
                                  height: "56px",
                                  boxSizing: "border-box"
                                }}
                              >
                                <CardExpiryElement options={{
                                  style: {
                                    base: {
                                      fontSize: "15px",
                                      color: '#2b2b2b',
                                      '::placeholder': {
                                        color: '#a2a2a2',
                                      },
                                    },
                                    invalid: {
                                      color: "#DC2626"
                                    },
                                  }
                                }} />
                              </Box>
                              {this.state.cardErrors.cardExpiryError && <Typography sx={{
                                color: "#DC2626",
                                fontSize: "12px",
                                fontFamily: "Inter",
                                fontWeight: 400
                              }}>{this.state.cardErrors.cardExpiryError}</Typography>}
                          </Grid>
                          <Grid style={{ paddingTop: '15px' }} item xs={6} sm={2}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="cvc">
                              CVC / CCV
                            </InputLabel>
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                sx={{
                                  border: this.handleErrorColor(this.state.cardErrors.cardCVCError , "1px solid #DC2626" , "1px solid #CBD5E1"),
                                  borderRadius: "8px",
                                  padding: "10px 8px",
                                  height: "56px",
                                  boxSizing: "border-box"
                                }}
                              >
                                <CardCvcElement options={{
                                  style: {
                                    base: {
                                      fontSize: "15px",
                                      color: '#2b2b2b',
                                      '::placeholder': {
                                        color: '#a2a2a2',
                                      },
                                    },
                                    invalid: {
                                      color: "#DC2626"
                                    },
                                  }
                                }} />
                              </Box>
                              {this.state.cardErrors.cardCVCError && <Typography sx={{
                                color: "#DC2626",
                                fontSize: "12px",
                                fontFamily: "Inter",
                                fontWeight: 400
                              }}>{this.state.cardErrors.cardCVCError}</Typography>}
                          </Grid>
                          <Grid item xs={12} sm={2} ></Grid>
                          <Grid item xs={12} sm={3} style={{ paddingTop: '10px' }}>
                            <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '14px', fontWeight: 400, color: '#64748B' }} >
                              We accept the following card types:
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              <img
                                src={masterCard.default}
                                alt="Card icon"
                                style={{ width: '67px', height: '39px' }}
                              />
                              <img
                                src={visaCard.default}
                                alt="Card icon"
                                style={{ width: '67px', height: '39px' }}
                              />
                              <img
                                src={FrameCard.default}
                                alt="Card icon"
                                style={{ width: '67px', height: '39px' }}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h6" sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: '#64748B' }}>Billing Address</Typography>
                          </Grid>
                          <Grid item xs={12} sm={3} style={{ paddingTop: '15px' }}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="addressLine1">
                              Address Line 1
                            </InputLabel>
                            <TextField
                              data-test-id="addressLine1"
                              sx={{ marginTop: '6px' }}
                              name="addressLine1"
                              placeholder="Enter Address Line 1"
                              error={touched.addressLine1 && Boolean(errors.addressLine1)}
                              value={values.addressLine1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  height: "10px",
                                  fontFamily: 'Inter',
                                  outline: 'none',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                },
                              }}
                            />
                            {this.getErrorMessage(touched, errors, "addressLine1")}
                          </Grid>
                          <Grid item xs={12} sm={3} style={{ paddingTop: '15px' }}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="addressLine2">
                              Address Line 2
                            </InputLabel>
                            <TextField
                              data-test-id="addressLine2"
                              sx={{ marginTop: '6px' }}
                              name="addressLine2"
                              placeholder="Enter Address Line 2"
                              error={touched.addressLine2 && Boolean(errors.addressLine2)}
                              value={values.addressLine2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  height: "10px",
                                  fontFamily: 'Inter',
                                  outline: 'none',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                },

                              }}
                            />
                            {this.getErrorMessage(touched, errors, "addressLine2")}
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: '5px' }}></Grid>
                          <Grid item xs={6} sm={3} style={{ paddingTop: '15px' }}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="state">
                              State
                            </InputLabel>
                            <TextField
                              data-test-id="state"
                              sx={{ marginTop: '6px' }}
                              name="state"
                              placeholder="Enter State"
                              error={touched.state && Boolean(errors.state)}
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  height: "10px",
                                  fontFamily: 'Inter',
                                  outline: 'none',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                },

                              }}
                            />
                            {this.getErrorMessage(touched, errors, "state")}
                          </Grid>
                          <Grid item xs={6} sm={3} style={{ paddingTop: '15px' }}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="city">
                              City
                            </InputLabel>
                            <TextField
                              data-test-id="city"
                              sx={{ marginTop: '6px' }}
                              name="city"
                              placeholder="Enter City"
                              error={touched.city && Boolean(errors.city)}
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  height: "10px",
                                  fontFamily: 'Inter',
                                  outline: 'none',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                },

                              }}
                            />
                            {this.getErrorMessage(touched, errors, "city")}
                          </Grid>
                          <Grid item xs={6} sm={2} style={{ paddingTop: '15px' }}>
                            <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter' }} htmlFor="zipCode">
                              ZIP Code
                            </InputLabel>
                            <TextField
                              data-test-id="zipCode"
                              sx={{ marginTop: '6px' }}
                              name="zipCode"
                              placeholder="Enter ZIP Code"
                              error={touched.zipCode && Boolean(errors.zipCode)}
                              value={values.zipCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  height: "10px",
                                  fontFamily: 'Inter',
                                  outline: 'none',
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                  },
                                },

                              }}
                            />
                            {this.getErrorMessage(touched, errors, "zipCode")}
                          </Grid>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={12} sm={4} style={{ paddingTop: '30px' }}>
                            <Button
                              data-test-id='submitBtn'
                              fullWidth
                              type="submit"
                              variant="contained"
                              color="primary"
                              sx={{
                                background: 'linear-gradient(90deg, #f7b733, #dd8723)',
                                fontWeight: 600,
                                textTransform: 'none',
                                fontFamily: 'Inter',
                              }}
                              disabled={!stripe}
                            >
                              Add payment method
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={4} style={{ paddingTop: '30px' }}>
                            <Button
                              data-test-id='cancelBtn'
                              onClick={() => { this.props.navigation.navigate('SetUpSubscription') }}
                              fullWidth
                              variant="contained"
                              sx={{
                                background: '#f1f4f9',
                                fontWeight: 600,
                                textTransform: 'none',
                                fontFamily: 'Inter',
                                color: '#788393',
                                ":hover": {
                                  background: '#f1f4f9',
                                },
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )
              }}
            </ElementsConsumer>
          </Elements>

        </Box>
      </Layout>
    );
  }
}

// Customizable Area End
