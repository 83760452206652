import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const Timer = ({styles, onResenCode}: any) => {
    const [time, setTime] = useState(120);

    useEffect(() => {
        if (time <= 0) return;

        const timerId = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId);
    }, [time]);

    const formatTime = (seconds: any) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${String(secs).padStart(2, '0')}`;
    };

    return (
        <Typography sx={styles.resend} alignItems={"center"}>
            <Button style={{...styles.btn, color: !time ? styles.resendRedBtn.color : styles.resendBtn.color}} disabled={!!time} onClick={() => {
                onResenCode()
                setTime(120);
            }}>Resend code</Button>
            {time ? <Box sx={styles.time}>
                in {formatTime(time)} Min
            </Box> : ""}
        </Typography>
    );
};

export default Timer;
