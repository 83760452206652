Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.STRIPE_PUBLISHABLE_KEY = "pk_test_51PdGE62MBo87uMMSFTEc6xlR72AULNbdDfZ22iAwrqAF0ePUSMifYVV4JpcT47Ko4A8I15fsjgeqAhboHqiHco2H00iLO5LIdO";
  // Customizable Area End
  