import React from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Formik, FormikErrors, FormikTouched } from "formik";
import { Stack, Typography, Button, InputLabel, TextField } from '@mui/material/';
import * as Yup from 'yup';
import { TouchedType, ErrorType, FormValues, PaymentMethodProps } from '../types.web';
import { initialCardErrors } from "./StripeUpdatePaymentMethod.web";

export const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
};
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    addressLine2: Yup.string().required('Address Line 2 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('ZIP Code is required'),
});

export default function PaymentMethod({ handleClose, setValues, cardErrors, setErrors, handleCardNumberChange, handleError }: PaymentMethodProps) {
    const stripe = useStripe();
    const elements = useElements();

    const onSubmit = async (values: FormValues) => {
        if (cardErrors.cardNumberError || !stripe || !elements) {
            return;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
            return;
        }
        setErrors(initialCardErrors);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
                name: values.firstName,
                address: {
                    city: values.city,
                    line1: values.addressLine1,
                    line2: values.addressLine2,
                    postal_code: values.zipCode,
                    state: values.state,
                },
            },
            metadata: {
                firstName: values.firstName,
                lastName: values.lastName,
            },
        });
        if (error) {
            handleError(error);
        }
        if (paymentMethod?.id) {
            setValues({ ...values, paymentMethodId: paymentMethod.id })
            handleClose("card-verification")
        }
    }
    const handleAlphaValues = (e: any, setFieldValue: Function) => {
        if ((/^[a-zA-Z\s]*$/).test(e.target.value)) {
            setFieldValue(e.target.name, e.target.value);
        }
    };
    const handleIntegerValues = (e: any, setFieldValue: Function) => {
        if ((/^\d+$/).test(e.target.value)) {
            setFieldValue(e.target.name, e.target.value);
        }
    };
    const getErrorMessage = (
        touched: FormikTouched<TouchedType>,
        errors: FormikErrors<ErrorType>,
        value: string
    ) => {
        return (
            touched[value as keyof TouchedType] &&
            errors[value as keyof ErrorType] && (
                <Typography
                    style={{ marginTop: "2px", fontSize: "12px", color: "#DC2626", fontWeight: 400 }}
                >
                    {errors[value as keyof TouchedType]}
                </Typography>
            )
        );
    };
    return (
        <Formik
            data-test-id='formik'
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ handleChange, values, handleBlur, touched, errors, setFieldValue, handleSubmit }) => (
                <form data-test-id="subscription-form" style={{ margin: 0 }} onSubmit={handleSubmit}>
                    <Stack gap={"38px"}>
                        <Stack gap={"12px"} sx={{
                            ".ElementsApp .InputElement": {
                                border: "1px solid !important"
                            }
                        }}>
                            <Typography sx={{
                                color: "#64748B",
                                fontWeight: 700,
                                fontFamily: "Inter",
                                fontSize: "16px"
                            }}>Card Information</Typography>

                            <Grid container spacing={"20px"}>
                                <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                    <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="firstName">
                                        Card Number
                                    </InputLabel>
                                    <Box
                                        data-testid="card-number-element"
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"center"}
                                        sx={{
                                            border: cardErrors.cardNumberError ? "1px solid #DC2626" : "1px solid #CBD5E1",
                                            borderRadius: "8px",
                                            padding: "10px 8px",
                                            height: "56px",
                                            boxSizing: "border-box"
                                        }}
                                    >
                                        <CardNumberElement
                                            onChange={handleCardNumberChange}
                                            options={{
                                                placeholder: "0000-0000-0000-0000",
                                                style: {
                                                    base: {
                                                        fontSize: "15px",
                                                        color: cardErrors.cardNumberError ? "#DC2626" : "#2b2b2b",
                                                        '::placeholder': {
                                                            color: '#a2a2a2',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: "#DC2626"
                                                    },
                                                }
                                            }} />
                                    </Box>
                                    {cardErrors.cardNumberError && <Typography sx={{
                                        color: "#DC2626",
                                        fontSize: "12px",
                                        fontFamily: "Inter",
                                        fontWeight: 400
                                    }}>{cardErrors.cardNumberError}</Typography>}
                                </Grid>
                                <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                    <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="lastName">
                                        Expiration date
                                    </InputLabel>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"center"}
                                        sx={{
                                            border: cardErrors.cardExpiryError ? "1px solid #DC2626" : "1px solid #CBD5E1",
                                            borderRadius: "8px",
                                            padding: "10px 8px",
                                            height: "56px",
                                            boxSizing: "border-box"
                                        }}
                                    >
                                        <CardExpiryElement options={{
                                            style: {
                                                base: {
                                                    fontSize: "15px",
                                                    color: '#2b2b2b',
                                                    '::placeholder': {
                                                        color: '#a2a2a2',
                                                    },
                                                },
                                                invalid: {
                                                    color: "#DC2626"
                                                },
                                            }
                                        }} />
                                    </Box>
                                    {cardErrors.cardExpiryError && <Typography sx={{
                                        color: "#DC2626",
                                        fontSize: "12px",
                                        fontFamily: "Inter",
                                        fontWeight: 400
                                    }}>{cardErrors.cardExpiryError}</Typography>}
                                </Grid>
                                <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                    <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="lastName">
                                        CVC / CCV
                                    </InputLabel>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"center"}
                                        sx={{
                                            border: cardErrors.cardCVCError ? "1px solid #DC2626" : "1px solid #CBD5E1",
                                            borderRadius: "8px",
                                            padding: "10px 8px",
                                            height: "56px",
                                            boxSizing: "border-box"
                                        }}
                                    >
                                        <CardCvcElement options={{
                                            style: {
                                                base: {
                                                    fontSize: "15px",
                                                    color: '#2b2b2b',
                                                    '::placeholder': {
                                                        color: '#a2a2a2',
                                                    },
                                                },
                                                invalid: {
                                                    color: "#DC2626"
                                                },
                                            }
                                        }} />
                                    </Box>
                                    {cardErrors.cardCVCError && <Typography sx={{
                                        color: "#DC2626",
                                        fontSize: "12px",
                                        fontFamily: "Inter",
                                        fontWeight: 400
                                    }}>{cardErrors.cardCVCError}</Typography>}
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack gap={"12px"}>
                            <Typography sx={{
                                color: "#64748B",
                                fontWeight: 700,
                                fontFamily: "Inter",
                                fontSize: "16px"
                            }}>Personal Data</Typography>
                            <Grid container spacing={"20px"}>
                                <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                    <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="firstName">
                                        First Name
                                    </InputLabel>
                                    <TextField
                                        data-test-id="firstName"
                                        name="firstName"
                                        sx={{
                                            "input": {
                                                padding: "10px 8px",
                                                height: "56px",
                                                boxSizing: "border-box",
                                            },
                                            ".MuiOutlinedInput-notchedOutline": {
                                                border: "1px solid #CBD5E1",
                                                borderRadius: "8px",
                                            }
                                        }}
                                        placeholder="First name"
                                        error={touched.firstName && Boolean(errors.firstName)}
                                        value={values.firstName}
                                        onChange={(e) => handleAlphaValues(e, setFieldValue)}
                                        onBlur={handleBlur}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                    />
                                    {getErrorMessage(touched, errors, "firstName")}
                                </Grid>
                                <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                    <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="lastName">
                                        Last Name
                                    </InputLabel>
                                    <TextField
                                        data-test-id="lastName"
                                        sx={{
                                            "input": {
                                                padding: "10px 8px",
                                                height: "56px",
                                                boxSizing: "border-box",
                                            },
                                            ".MuiOutlinedInput-notchedOutline": {
                                                border: "1px solid #CBD5E1",
                                                borderRadius: "8px",
                                            }
                                        }}
                                        name="lastName"
                                        placeholder="Last name"
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        value={values.lastName}
                                        onChange={(e) => handleAlphaValues(e, setFieldValue)}
                                        onBlur={handleBlur}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                    />
                                    {getErrorMessage(touched, errors, "lastName")}
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack gap={"12px"}>
                            <Typography sx={{
                                color: "#64748B",
                                fontWeight: 700,
                                fontFamily: "Inter",
                                fontSize: "16px"
                            }}>Billing Address</Typography>
                            <Stack gap={"24px"}>
                                <Grid container spacing={"20px"}>
                                    <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="addressLine1">
                                            Address Line 1
                                        </InputLabel>
                                        <TextField
                                            data-test-id="addressLine1"
                                            sx={{
                                                "input": {
                                                    padding: "10px 8px",
                                                    height: "56px",
                                                    boxSizing: "border-box",
                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #CBD5E1",
                                                    borderRadius: "8px",
                                                }
                                            }}
                                            name="addressLine1"
                                            placeholder="Address Line 1"
                                            error={touched.addressLine1 && Boolean(errors.addressLine1)}
                                            value={values.addressLine1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            variant="outlined"
                                        />
                                        {getErrorMessage(touched, errors, "addressLine1")}
                                    </Grid>
                                    <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="addressLine2">
                                            Address Line 2
                                        </InputLabel>
                                        <TextField
                                            data-test-id="addressLine2"
                                            sx={{
                                                "input": {
                                                    padding: "10px 8px",
                                                    height: "56px",
                                                    boxSizing: "border-box",
                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #CBD5E1",
                                                    borderRadius: "8px",
                                                }
                                            }}
                                            name="addressLine2"
                                            placeholder="Address Line 2"
                                            error={touched.addressLine2 && Boolean(errors.addressLine2)}
                                            value={values.addressLine2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            variant="outlined"
                                        />
                                        {getErrorMessage(touched, errors, "addressLine2")}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={"20px"}>
                                    <Grid item style={{ paddingTop: '15px' }} xs={12} md={6} lg={4}>
                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="state">
                                            State
                                        </InputLabel>
                                        <TextField
                                            data-test-id="state"
                                            sx={{
                                                "input": {
                                                    padding: "10px 8px",
                                                    height: "56px",
                                                    boxSizing: "border-box",
                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #CBD5E1",
                                                    borderRadius: "8px",
                                                }
                                            }}
                                            name="state"
                                            placeholder="State"
                                            error={touched.state && Boolean(errors.state)}
                                            value={values.state}
                                            onChange={(e) => handleAlphaValues(e, setFieldValue)}
                                            onBlur={handleBlur}
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                        />
                                        {getErrorMessage(touched, errors, "state")}
                                    </Grid>
                                    <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="city">
                                            City
                                        </InputLabel>
                                        <TextField
                                            data-test-id="city"
                                            sx={{
                                                "input": {
                                                    padding: "10px 8px",
                                                    height: "56px",
                                                    boxSizing: "border-box",
                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #CBD5E1",
                                                    borderRadius: "8px",
                                                }
                                            }}
                                            name="city"
                                            placeholder="City"
                                            error={touched.city && Boolean(errors.city)}
                                            value={values.city}
                                            onChange={(e) => handleAlphaValues(e, setFieldValue)}
                                            onBlur={handleBlur}
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                        />
                                        {getErrorMessage(touched, errors, "city")}
                                    </Grid>
                                    <Grid style={{ paddingTop: '15px' }} item xs={12} md={6} lg={4}>
                                        <InputLabel sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Inter', color: "#64748B", mb: "4px" }} htmlFor="zipCode">
                                            ZIP Code
                                        </InputLabel>
                                        <TextField
                                            data-test-id="zipCode"
                                            sx={{
                                                "input": {
                                                    padding: "10px 8px",
                                                    height: "56px",
                                                    boxSizing: "border-box",
                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #CBD5E1",
                                                    borderRadius: "8px",
                                                }
                                            }}
                                            name="zipCode"
                                            placeholder="125251"
                                            error={touched.zipCode && Boolean(errors.zipCode)}
                                            value={values.zipCode}
                                            onChange={(e) => handleIntegerValues(e, setFieldValue)}
                                            onBlur={handleBlur}
                                            fullWidth
                                            variant="outlined"
                                        />
                                        {getErrorMessage(touched, errors, "zipCode")}
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack sx={{
                        mt: "3.5rem",
                        "@media(max-width: 991px)": {
                            flexDirection: "column",
                            gap: "12px"
                        }
                    }} justifyContent={"center"} alignItems={"center"} direction={"row"} gap={"38px"} width={"100%"} className='modal-btn-group'>
                        <Button type="submit" disabled={!stripe} className='btn edit-btn' data-testid="btn1">{"Update payment method"}</Button>
                        <Button className='btn cancel-btn' data-testid="btn2" onClick={() => handleClose("update-payment", false)} style={{ color: "#64748B", background: "#F1F5F9" }}>{"Cancel"}</Button>
                    </Stack>
                </form>
            )
            }
        </Formik >
    )
}