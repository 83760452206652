Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "/bx_block_profile/profile";
exports.updateCustomizableProfileEndPoint = "/bx_block_profile/profile";
exports.verifyPhoneOtpEndPoint = "/bx_block_profile/profiles/verify_mobile_otp";
exports.verifyEmailOtpEndPoint = "/bx_block_profile/profiles/verify_email_otp";
exports.listDependentEndPoint = "/bx_block_profile/dependents/";
exports.resenCodeEndPoint = "bx_block_profile/profiles";

exports.userProfileObj = {
  pageTitleTxt: "My profile",
  editBtnTxt: "Edit profile",
  personalInfoTxt: "Personal information",
  contactTxt: "Contacts",
  phpMembershipTxt: "Advantage PHC membership",
  relationshipTxt: "Relationship",
  firstNameTxt: "first name",
  lastNameTxt: "last name",
  birthDateTxt: "birthdate",
  phoneNumberTxt: "phone number",
  emailTxt: "email",
  participantIdTxt: "Participant ID",
  dependantsTxt: "Dependants",
  addNewBtnTxt: "Add a new dependant",
  saveChangesBtnTxt: "Save Changes",
  cancelTxt: "Cancel",
  finishTxt: "Finish",
  newDependantTxt: "New dependant",
  userNameTxt: "Eugene Clark",

  dataVerificationTxt: "Data verification",
  phoneVerifyTxt: "To save changes please verify your new phone number by clicking the button below",
  phoneVerifyBtnTxt: "Verify phone number",
  emailVerifyTxt: "To save changes please verify your new email address by clicking the button below ",
  emailVerifyBtnTxt: "Verify email address",
  emailPhoneVerifyTxt: "To save changes please verify your new email address and phone number by clicking the button below ",
  
  emailVerificationTxt: "Email verification",
  phoneVerificationTxt: "Phone number verification",
  verificationEmailCodeTxt: "Please enter the verification code sent to your email address.",
  verificationPhoneCodeTxt: "Please enter the verification code sent to your mobile number.",
  smsValidTxt: "(SMS code is valid for 10 mins)",
  emailValidTxt: "(Code is valid for 10 mins)",

  phoneVerifySuccessTxt: "Phone number verification was completed successfully",
  emailVerifySuccessTxt: "Email address verification was completed successfully",
  backToProfileTxt: "Back to My Profile",
}
// Customizable Area End
