import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { mark } from '../assets';
import { CustomModal } from './SuccessDialog.web';

interface Props {
    open: boolean;
    handleClose: any;
    userName: string;
}

export default function CardVerificationDialog({ open, handleClose, userName }: Props) {

    return (
        <CustomModal open={open} handleClose={handleClose}>
            <DialogContent>
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} marginTop={"1rem"} gap={"20px"}>
                    <img src={mark.default} />
                    <Stack alignItems={"center"} justifyContent={"center"} gap={"64px"}>
                        <Stack justifyContent={"center"} alignItems={"center"} gap={"8px"}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#0F172A" }}>
                                {"Card verification"}
                            </Typography>
                            <Stack sx={{
                                width: {md: "531px"}
                            }}>
                                <Typography id="modal-modal-description" sx={{ textAlign: "center", color: "#64748B" }}>
                                    Dear <span style={{textTransform: "capitalize"}}>{userName || "user"}</span>, thank you for updating your payment method!
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ textAlign: "center", color: "#64748B" }}>
                                    {"To finish the process, please verify your card by pressing the button below"}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            "@media(max-width: 991px)": {
                                flexDirection: "column",
                                gap: "12px"
                            }
                        }} direction={"row"} gap={"28px"} className='modal-btn-group'>
                            <Button className='btn edit-btn' data-testid="btn1" onClick={() => handleClose("payment-deduction")}>{"Verify Card"}</Button>
                            <Button className='btn cancel-btn' data-testid="btn2" onClick={() => handleClose("card-verification", false)} style={{ color: "#64748B", background: "#F1F5F9" }}>{"Cancel"}</Button>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
        </CustomModal>
    );
}