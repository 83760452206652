Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorPasswordMessage = 'Please enter a valid password that meets the criteria';
exports.errorConfirmPasswordMessage = 'Passwords do not match';
exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.emailVerificatioTxt = "Email verification";
exports.verificationEmailCodeTxt = "Please enter the verification code sent to your email address.";
exports.emailValidTxt = "(Code is valid for 10 mins)";

exports.phoneVerificationTxt = "Phone number verification";
exports.verificationPhoneCodeTxt = "Please enter the verification code sent to your mobile number.";
exports.smsValidTxt = "(SMS code is valid for 10 mins)";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodTypePutDetail = "PUT";
exports.apiMethodTypePatchDetail = "PATCH";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.signupAPiEndPoint = "account_block/accounts";
exports.getApiMethod = 'GET';
exports.termsAndCondEndPoint = "bx_block_terms_and_conditions/terms_and_conditions";
exports.privacyPolicyEndPoint = "bx_block_terms_and_conditions/privacy_policies";

exports.otpVerifyApi = "account_block/accounts/verify_mobile_otp"
exports.emailOtpVerifyApi = "account_block/accounts/email_confirmations"
exports.phoneResendCodeApi = "account_block/accounts/resend_mobile_otp"
exports.emailResendCodeApi = "account_block/accounts/resend_email_otp"
exports.listSalesforceUsersApi = "account_block/accounts/list_salesforce_users_with_same_email"
exports.addRolesApi = "account_block/accounts/add_role_in_salesforce_users"
// Customizable Area End
