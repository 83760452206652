import React from 'react'

const CloseButton = ({onClose}:{onClose: any}) => {
    return (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <button onClick={() => onClose()}>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.72406 0.282528C9.58394 0.142089 9.3937 0.0631641 9.19531 0.0631641C8.99692 0.0631641 8.80669 0.142089 8.66656 0.282528L4.99906 3.94253L1.33156 0.275028C1.19144 0.134589 1.0012 0.0556641 0.802813 0.0556641C0.604424 0.0556641 0.414186 0.134589 0.274063 0.275028C-0.0184375 0.567528 -0.0184375 1.04003 0.274063 1.33253L3.94156 5.00003L0.274063 8.66753C-0.0184375 8.96003 -0.0184375 9.43253 0.274063 9.72503C0.566563 10.0175 1.03906 10.0175 1.33156 9.72503L4.99906 6.05753L8.66656 9.72503C8.95906 10.0175 9.43156 10.0175 9.72406 9.72503C10.0166 9.43253 10.0166 8.96003 9.72406 8.66753L6.05656 5.00003L9.72406 1.33253C10.0091 1.04753 10.0091 0.567528 9.72406 0.282528Z" fill="#0F172A" />
                </svg>
            </button>
        </div>
    )
}

export default CloseButton