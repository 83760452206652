import * as React from 'react';
import Button from '@mui/material/Button';
import {Box} from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import OtpInput from 'react-otp-input';
import Timer from '../../../customisableuserprofiles/src/components/Timer';
import { VerifyModalType } from '../Settings2Controller';

interface Props {
    open: boolean;
    onClickVerify: any;
    phcPic: any;

    modalData: VerifyModalType;
    otp: string;
    handleOtp:any;
    otpError: boolean;
    otpErrorMessages: {
        otpValidationError: string,
        codeSentSuccess: string
    };
    handleResendCode: any;
    resendOtpSent: boolean;
}

export default function AuthVerificationModal({ open, onClickVerify, modalData, phcPic, otp, handleOtp, otpError, handleResendCode, resendOtpSent, otpErrorMessages }: Props) {
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{
                fontFamily: "Inter",
                ".MuiDialog-paperWidthSm": {
                    borderRadius: "16px",
                    padding: "12px 24px",
                    overflow: "hidden",
                },
                ".MuiDialogContent-root": {
                    overflow: "hidden",
                },
                "#modal-modal-title": {
                    fontWeight: 700,
                    fontFamily: "Inter",
                    fontSize: "18.31px",
                },
                "#modal-modal-description": {
                    textAlign: "center",
                    color: "#0F172A",
                    fontSize: "14px",
                    fontWeight: 400,
                    margin: 0,
                    fontFamily: "Inter",
                },
                "& .btn": {
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Inter",
                    textTransform: "initial",
                    letterSpacing: 0,
                    height: "56px",
                    borderRadius: "8px",
                    padding: "16px",
                    width: "100%"
                },
                "& .edit-btn": {
                    background: `linear-gradient(99.09deg, #FCD34D 2.64%, #D97706 100%)`,
                    color: "#fff",
                },
                "& .cancel-btn": {
                    background: `#E2E8F0`,
                    color: "#64748B",
                },
                "@media(max-width: 420px)": {
                    ".MuiDialogContent-root": {
                        padding: 0
                    },
                    ".MuiDialog-paperWidthSm": {
                        margin: 0
                    },
                    ".MuiTypography-body1": {
                        width: "100%"
                    },
                }
            }}
        >
            <DialogContent>
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} gap={"10px"}>
                    <img src={phcPic} />
                    <Stack justifyContent={"center"} alignItems={"center"}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#0F172A", fontSize: "24px", fontWeight: 400 }}>
                            {modalData.title}
                        </Typography>
                        <Typography id="modal-modal-description" width={"430px"}>
                            {modalData.description}
                        </Typography>
                        <Typography id="modal-modal-description" style={{ textAlign: "center", color: "#64748B", fontWeight: 400, fontSize: "14px" }}>
                            (Code is valid for 10 mins)
                        </Typography>
                        <Typography id="modal-modal-description" style={{ textAlign: "center", color: "#0F172A", fontWeight: 400, fontSize: "20px", margin: "1rem 0" }}>
                           {modalData.userData}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Box sx={styles.otpBox}>
                            <OtpInput
                                value={otp}
                                inputType="tel"
                                onChange={handleOtp}
                                containerStyle={{ outline: "none" }}
                                data-test-id="otpInput"
                                numInputs={4}
                                renderInput={(props) => <input {...props} placeholder="0"
                                    style={{
                                        ...props.style,
                                        borderColor: otpError ? 'red' : '#CBD5E1',
                                        display: 'flex',
                                        width: '56px',
                                        height: '56px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid #CBD5E1',
                                        borderRadius: '8px',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        fontFamily: "Inter",
                                        padding: '0',
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        margin: '8px',
                                    }} />}

                            />
                        </Box>
                        {otpError ? (
                            <Box sx={styles.otpError}>{otpErrorMessages.otpValidationError}</Box>
                        ) : null}
                        {resendOtpSent ? (
                            <Box sx={{...styles.otpError, color: "#047857"}}>{otpErrorMessages.codeSentSuccess}</Box>
                        ) : null}
                        <Timer styles={{resend: styles.resend, resendBtn: styles.resendBtn, time: styles.time, resendRedBtn: styles.resendRedBtn, btn: styles.btn}} onResenCode={handleResendCode} />
                    </Stack>
                    <Stack justifyContent={"center"} alignItems={"center"} direction={"row"}width={"100%"} className='modal-btn-group'>
                        <Button className='btn edit-btn' fullWidth data-testid="btn1" onClick={() => onClickVerify()}>{modalData.btn.title}</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
const styles = {
    valid : {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        color: '#64748B',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily:"Inter",
        margin: '3px 0px'
    },
    otpBox : {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
        fontFamily:"Inter"
    },
    otpInput : {
      
    },
    otpError : {
        fontWeight: '400',
        fontFamily:"Inter",
        color: '#DC2626',
        width:'100%',
        fontSize: '14px',
        display:'flex',
        justifyContent:'center'
    },
    resend : {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        fontSize: '14px',
        width: '100%',
        fontWeight: '600',
        fontFamily:"Inter"
    },
    resendBtn : {
        marginRight: '4px',
        color: '#A8A29E',
        fontFamily:"Inter"
    },
    resendRedBtn : {
        color: '#D97706',
    },
    btn: {
        marginRight: '4px',
        fontFamily:"Inter",
        fontSize: "14px",
        fontWeight: 600,
        padding: 0,
        textTransform: "none"
    },
    time : {
        color: '#0F172A',
        fontFamily:"Inter"
    },
    FontFam:{
        fontFamily:"Inter"
    }
};