import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { Radio } from '@mui/material';
import { mark2 } from '../assets';
import { CustomModal } from './SuccessDialog.web';

interface Props {
    open: boolean;
    handleClose: any;
}
const checkedIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#F59E0B" />
    <circle cx="10" cy="10" r="4" fill="white" />
</svg>
const uncheckedIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#64748B" />
</svg>

export default function PaymentVerificationDialog({ open, handleClose }: Props) {

    return (
        <CustomModal open={open} handleClose={handleClose} style={{
            ".MuiDialog-paperWidthSm": {
                height: "526px",
                borderRadius: "12px",
                padding: "12px 24px",
                overflow: "hidden",
            },
        }}>
            <DialogContent>
                <Stack height={"100%"} marginTop={"1rem"} justifyContent={"space-between"}>
                    <Stack gap={"8px"} alignItems={"center"} justifyContent={"center"}>
                        <img src={mark2.default} width={"56px"} height={"56px"} />
                        <Stack alignItems={"center"} justifyContent={"center"} gap={"40px"}>
                            <Stack justifyContent={"center"} alignItems={"center"} gap={"8px"}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#0F172A" }}>
                                    {"Card verification"}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ textAlign: "center", color: "#64748B" }}>
                                    {"To verify your credit card, our app will perform a test charge of $0.50. This charge is solely for verification purposes and will be immediately refunded to your account."}
                                </Typography>
                            </Stack>
                            <Stack gap={"8px"}>
                                <Typography sx={{ color: "#64748B", fontWeight: 400, fontFamily: "Inter", fontSize: "14px" }}>
                                    {"To continue, please provide your agreement regarding the operation by checking the box below: "}
                                </Typography>
                                <Stack direction={"row"} alignItems={"center"} width={"100%"} gap={"8px"}>
                                    <Radio
                                        checked
                                        icon={uncheckedIcon}
                                        checkedIcon={checkedIcon}
                                        name="radio-buttons"
                                        sx={{
                                            padding: 0,
                                            '&, &.Mui-checked': {
                                                color: '#64748B',
                                            },
                                        }}
                                    />
                                    <Typography sx={{ color: "#64748B", fontWeight: 700, fontFamily: "Inter", fontSize: "14px" }}>
                                        {"I allow Advantage PHC to verify my card by conducting a purchase for the amount of $0.50 "}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack sx={{
                        mb: "1rem",
                        "@media(max-width: 991px)": {
                            flexDirection: "column",
                            gap: "12px"
                        }
                    }} justifyContent={"center"} alignItems={"center"} direction={"row"} gap={"28px"} width={"100%"} className='modal-btn-group'>
                        <Button className='btn edit-btn' data-testid="btn1" onClick={() => handleClose("payment-processed")}>{"Continue"}</Button>
                        <Button className='btn cancel-btn' data-testid="btn2" onClick={() => handleClose("payment-deduction", false)} style={{ color: "#64748B", background: "#F1F5F9" }}>{"Cancel"}</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </CustomModal>
    );
}