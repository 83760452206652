import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import Layout from "../../landingpage/src/Layout.web";
import Sidebar from "./components/Sidebar.web";
import TwoFactorAuthentication, { getMobileHeader } from "./components/TwoFactorAuthentication.web";
import ChangePassword from "./components/ChangePassword.web";
import TermsModal from "./components/TermsModal.web";
import Modal from "./components/Modal.web";
import AuthVerificationModal from "./components/AuthVerificationModal.web";
import { ToastContainer } from "react-toastify";
import {Box} from "@mui/material"

import SubscriptionBilling from "./components/SubscriptionBilling.web";
import StripeUpdatePaymentMethod from "../../../blocks/stripepayments/src/components/StripeUpdatePaymentMethod.web";
import CardVerificationDialog from "../../../blocks/stripepayments/src/components/CardVerificationDialog.web";
import PaymentVerificationDialog from "../../../blocks/stripepayments/src/components/PaymentVerificationDialog.web";
import SuccessDialog from "../../../blocks/stripepayments/src/components/SuccessDialog.web";
import ErrorDialog from "../../../blocks/stripepayments/src/components/ErrorDialog.web";
import CircularProgressDialog from "../../../blocks/stripepayments/src/components/CircularProgressDialog.web";
import CancelPlanModal from '../../../blocks/subscriptionbilling2/src/components/CancelPlanModal.web';
import SuccessCancelPlanModal from '../../../blocks/subscriptionbilling2/src/components/SuccessCancelPlanModal';
import { Actions } from "./Settings2Controller";

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout navigation={this.props.navigation} id={""}>
        <ToastContainer />
        <Sidebar action={this.state.action} isActiveRoute={this.state.isActiveRoute} changeAction={this.changeAction} isMobileScreen={this.state.isMobileScreen} tab={this.state.tab} onClickTab={this.onTabClick} hideSubscriptionBilling={this.getIsSalesforceUser()}>
          {(!this.getIsSalesforceUser() && this.state.action.type === Actions.SUBSCRIPTION_BILLING) && (
            <Box sx={{width: "100%"}}>
              {getMobileHeader(this.onTabClick, "Subscription billing")}
              <SubscriptionBilling 
                onClickUpdate={this.onClickUpdatePayment} 
                onClickCancelPlan={this.handleCancelPlanModalOpen} 
                hasSubscription={this.state.hasSubscription}
                renewDate={this.state.renewDate}
                amount = {this.state.amount}
                cardNumber={this.state.cardNumber}
                cardType={this.state.cardType}
                duration={this.state.duration}
                cardExpiryMonth={this.state.expiryMonth || 0}
                cardExpiryYear={this.state.expiryYear || 0}
                paymentStatus={this.state.paymentStatus}
                isPlanCanceled={this.state.isPlanCanceled}
              />
            </Box>)}

          {(this.state.action.type === Actions.TWO_FACTOR_AUTHENTICATION) && <TwoFactorAuthentication user={{
            email: this.state.email,
            phone_number: this.state.formatedPhoneNumber
          }} selectedValue={this.state.AuthMethod} changeAuthMethod={this.changeAuthMethod} onSaveAuthMethod={this.updateAuthMethod} onClickCancel={this.onClickCancel} tab={this.state.tab} onClickTab={this.onTabClick} />}
          
          {(this.state.action.type === Actions.CHANGE_PASSWORD) && <ChangePassword inputErrors={this.state.inputErrors} errorMessages={this.state.errorMessages} handleInput={this.handleInput} isDisabled={this.state.btnContinueBlockStatus} onClickContinue={this.onClickSubmit} onClickReset={this.onClickReset} inputs={{
            old_password: this.state.old_password,
            new_password: this.state.new_password,
            confirm_password: this.state.confirm_password
          }} tab={this.state.tab} onClickTab={this.onTabClick} />}
        </Sidebar>

        <TermsModal open={this.state.isTermsModalOpen} onClose={this.handleTermsModal} title={this.state.termsData.title} data={this.state.termsData.data} />

        <Modal
          open={this.state.isModalOpen}
          onClose={() => this.handleModal(false)}
          modalData={this.state.modalData}
          onClickVerify={this.onClickSubmit}
        />

        <AuthVerificationModal
          open={this.state.isVerifyModalOpen}
          onClickVerify={this.onClickContinue}
          phcPic={this.state.phcPic}
          otp={this.state.otp}
          handleOtp={this.handleOtp}
          otpError={this.state.otpError}
          otpErrorMessages={this.state.otpErrorMessages}
          handleResendCode={this.sendResendCode}
          resendOtpSent={this.state.resendOtpSent}
          modalData={this.state.verifyModalData}
        />

        {this.state.isCancelPlanModalOpen && <CancelPlanModal open={this.state.isCancelPlanModalOpen} onClose={this.handleCancelPlanModalClose} 
          onCancelPlan={()=>{
             this.handleCancelPlan()
        }} renewDate={this.state.renewDate} />}

        {this.state.isSuccessCancelPlanModal && <SuccessCancelPlanModal open={this.state.isSuccessCancelPlanModal} onClose={this.handleSuccessCancelPlanModalClose}/>}


        {this.state.currentModal["update-payment"] && <StripeUpdatePaymentMethod open={this.state.currentModal["update-payment"]} handleClose={this.onClickUpdatePayment} setValues={this.setValues} />}
        {this.state.currentModal["card-verification"] && <CardVerificationDialog open={this.state.currentModal["card-verification"]} handleClose={this.onClickUpdatePayment} userName={this.state.userName} />}
        {this.state.currentModal["payment-deduction"] && <PaymentVerificationDialog open={this.state.currentModal["payment-deduction"]} handleClose={this.onClickUpdatePayment} />}
        {this.state.currentModal["payment-processed"] && (<CircularProgressDialog handleClose={this.onClickUpdatePayment} processPayment={this.paymentProcessingMethod} />)}
        {this.state.currentModal["payment-success"] && <SuccessDialog open={this.state.currentModal["payment-success"]} handleClose={this.redirectToSignInPage} />}
        {this.state.currentModal["payment-fail"] && <ErrorDialog open={this.state.currentModal["payment-fail"]} handleClose={this.onClickUpdatePayment} />}
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
