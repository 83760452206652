import React, { useState } from "react";
import {
  Grid,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ButtonGroup from "./ButtonGroup.web";
import { Block, getMobileHeader } from "./TwoFactorAuthentication.web";
const { settings } = require("../config");

interface Props {
  inputs: { old_password: string, new_password: string, confirm_password: string }
  inputErrors: { newPasswordError: boolean, oldPasswordError: boolean, confirmNewPasswordError: boolean };
  errorMessages: { newPasswordErrorMsg: string, oldPasswordErrorMsg: string, confirmNewPasswordErrorMsg: string };
  handleInput: any;
  isDisabled: boolean;
  onClickContinue: any;
  onClickReset: any;
  tab: boolean;
  onClickTab: any;
}

const ChangePassword = ({ inputs, inputErrors, errorMessages, handleInput, isDisabled, onClickContinue, onClickReset, tab, onClickTab }: Props) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  return (
    <Block sx={{
      "@media(max-width: 991px)": {
        height: "100%",
        overflow: "auto"
      }
    }}>
      {getMobileHeader(onClickTab, "Change password")}
      <Stack gap={"16px"}>
        <Typography className="heading" sx={{
          fontSize: "20px", "@media(max-width: 991px)": {
            display: "none"
          }
        }}>
          Password settings
        </Typography>
        <Stack gap={"69px"} sx={{
          "@media(max-width: 991px)": {
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between"
          }
        }}>
          <Grid container rowSpacing={"20px"}>
            <Grid item xs={12}>
              <Stack gap={"4px"}>
                <Typography
                  className="heading"
                  style={{ color: "#64748B", fontSize: "14px" }}
                >
                  Old password<span style={styles.required}>*</span>
                </Typography>
                <TextField
                  type="password"
                  data-test-id="password"
                  error={inputErrors.oldPasswordError}
                  name="old password"
                  value={inputs.old_password}
                  placeholder="Old password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  style={styles.phoneInput}
                  onChange={(e: any) => handleInput(e)}
                />
                {inputErrors.oldPasswordError &&
                  <Box sx={styles.errorMsg}>{errorMessages.oldPasswordErrorMsg}</Box>
                }
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={"8px"}>
                <Typography
                  className="heading"
                  style={{ color: "#64748B", fontSize: "14px" }}
                >
                  New password<span style={styles.required}>*</span>
                </Typography>
                <TextField
                  data-test-id="password"
                  error={inputErrors.newPasswordError}
                  value={inputs.new_password}
                  name="new password"
                  placeholder="New password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  style={styles.phoneInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e: any) => handleInput(e)}
                />
                {inputErrors.newPasswordError &&
                  <Box sx={styles.errorMsg}>{errorMessages.newPasswordErrorMsg}</Box>
                }
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={styles.passwordFormat}
                  paragraph
                >
                  1. At least one capital letter
                  <br />
                  2. At least one lowercase letter
                  <br />
                  3. At least one number
                  <br />
                  4. At least one special character like $, @, #<br />
                  5. Minimum character length is 8 characters
                  <br />
                  6. Password has to be different from previously used one
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={"4px"}>
                <Typography
                  className="heading"
                  style={{ color: "#64748B", fontSize: "14px" }}
                >
                  New password confirmation<span style={styles.required}>*</span>
                </Typography>
                <TextField
                  data-test-id="confirmPassword"
                  error={inputErrors.confirmNewPasswordError}
                  value={inputs.confirm_password}
                  name="confirm password"
                  placeholder="Confirm password"
                  type={showConfirmPassword ? 'text' : 'password'} variant="outlined" fullWidth margin="normal" required style={styles.confirmpassInupt}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e: any) => handleInput(e)}
                />
                {inputErrors.confirmNewPasswordError &&
                  <Box sx={styles.errorMsg}>{errorMessages.confirmNewPasswordErrorMsg}</Box>
                }
              </Stack>
            </Grid>
          </Grid>
          <Stack gap={"16px"} sx={{
            "@media(max-width: 991px)": {
              minHeight: "100px",
              width: "100%",
              background: "#fff"
            }
          }}>
            <ButtonGroup
              sx={{
                "@media(max-width: 991px)": {
                  flexDirection: "column"
                }
              }}
              btn1Title={settings.changePassTxt}
              btn2Title={settings.resetTxt}
              isDisabled={isDisabled} onClickContinue={onClickContinue} onClickCancel={onClickReset} />
          </Stack>
        </Stack>
      </Stack>
    </Block>
  );
};

const styles = {
  inputLable: {
    fontSize: "14px",
    display: "flex",
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#64748B",
  },
  required: {
    color: "red",
    marginLeft: "3px",
  },
  phoneInput: {
    height: "56px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Inter",
  },
  passwordInput: {
    height: "56px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Inter",
  },
  confirmpassInupt: {
    height: "56px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Inter",
  },
  errorMsg: {
    color: "#DC2626",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Inter",
  },
  passwordFormat: {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Inter",
    color: "#64748B",
    margin: 0,
  },
  FontFam: {
    fontFamily: "Inter",
  },
};
export default ChangePassword;
