import { Actions } from "./Settings2Controller"
const { settings } = require("./config")

export const SidebarData = [
    { action: Actions.SUBSCRIPTION_BILLING, title: settings.subscriptionTxt },
    { action: Actions.TWO_FACTOR_AUTHENTICATION, title: settings.twoFactorTxt },
    { action: Actions.CHANGE_PASSWORD, title: settings.changePassTxt },
    { action: Actions.TERMS_AND_CONDITIONS, title: settings.termsTxt },
    { action: Actions.PRIVACY_POLICY, title: settings.privacyPolicyTxt },
    { action: Actions.DELETE_ACCOUNT, title: settings.deleteAccountTxt },
]