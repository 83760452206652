import { styled, Stack, Box } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const Theme = {
    InfoBox: styled(Stack)({
        "& .label-style": { color: "#64748B", fontSize: "12px", fontFamily: "Inter" },
        "& .data-style": { color: "#1E293B", fontSize: "14px", fontFamily: "Inter" },
        "& .infoBox-headline": {
            color: "#334155",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "28px",
            letterSpacing: 0,
        },
        "& .infoBox-title": {
            color: "#64748B",
            fontFamily: "Inter",
            fontSize: "16px",
            textTransform: "capitalize",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: 0,
        },
        "& .infoBox-data": {
            color: "#1E293B",
            fontFamily: "Inter",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            letterSpacing: 0,
        },
        "& .add-new-btn": {
            width: "182px",
            height: "57px",
            borderRadius: "8px",
            padding: "2px 4px 3px 4px",
            gap: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #CBD5E1",
            textTransform: "none",
    
            p: {
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Inter",
                color: "#1E293B",
                letterSpacing: 0,
                width: "126px",
            }
        },
        "@media(max-width: 1140px)": {
            ".responsive-stack":{
                gap: "54px"
            }
        },
        "@media(max-width: 1070px)": {
            ".add-new-btn": {
                width: "100% !important"
            },
        },
        "@media(max-width: 920px)": {
            ".responsive-stack":{
                flexDirection: "column !important",
                gap: "16px"
            }
        },
    }),
    style: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "562px",
        height: "327px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: "12px",
        p: 4,
    },
    ModalBody: styled(Box)({
        button: {
            background: "none",
            border: "none",
        },
        "& #modal-modal-title": {
            color: "#334155",
            fontFamily: 'Inter',
            fontSize: '18.31px',
            margin: 0,
            fontWeight: 700,
            lineHeight: '24.42px'
        },
        "& #modal-modal-description": {
            color: "#64748B",
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '22px',
            textAlign: 'center',
        },
        "& .btn": {
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: "Inter",
            textTransform: "initial",
            letterSpacing: 0,
            height: "56px",
            borderRadius: "8px",
            padding: "16px",
            maxWidth: "240px",
            width: "100%"
        },
        "& .edit-btn": {
            background: `linear-gradient(99.09deg, #FCD34D 2.64%, #D97706 100%)`,
            color: "#fff",
        },
        "& .cancel-btn": {
            background: `#E2E8F0`,
            color: "#64748B",
        },
        "@media(max-width: 620px)": {
            width: "calc(100% - 50px)",
            
            ".btn": {
                maxWidth: "fit-content"
            },
            ".modal-btn-group": {
                justifyContent: "center",
                gap: "8px"
            },
            "#modal-modal-description": {
                width: "100%"
            },
        }
    }),
    CustomDatePicker: styled(DatePicker)({
        border: "none",
        height: '56px',
        "& div": {
          borderRadius: '8px',
          padding: "8px",
          height: "100%"
        },
        "& input": {
            color: "#94A3B8",
            fontSize: '16px',
            fontWeight: '400',
            fontFamily: "Inter",
        }
    }),
    Card: styled(Box)({
        maxWidth: "182px",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        overflow: "hidden",
        boxSizing: "border-box",
    
        "& .card-body": {
            padding: "6px 10px"
        },
        "& .card-header": {
            padding: "6px 10px",
            display: "flex",
            gap: "4px",
            height: "35px",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#E2E8F0",
            "& .svg": { border: "1px solid #64748B", borderRadius: "4px", padding: "6px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        "& .headline": {
            color: "#475569",
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
        },
    
        "@media(max-width: 1070px)": {
            maxWidth: "100%",
            ".dependant-details": {
                flexDirection: "row",
                justifyContent: "space-between"
            },
            "& .card-header": {
                padding: "20px 16px",
            },
            "& .card-body": {
                padding: "16px"
            },
            ".add-new-btn": {
                width: "100% !important"
            },
        }
    })
}