Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.patchMethod = "PATCH";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.privacyPolicyEndPoint = "bx_block_terms_and_conditions/privacy_policies";
exports.termsEndPoint = "bx_block_terms_and_conditions/terms_and_conditions";
exports.authMethodEndPoint = "account_block/accounts/verification_method";
exports.updateAuthMethodEndPoint = "account_block/accounts/update_verification_method";
exports.deleteAccountEndPoint = "account_block/accounts/delete_account";
exports.verifyDeleteAccountEndPoint = "account_block/accounts/verify_delete_account";
exports.getCustomizableProfileEndPoint = "/bx_block_profile/profile";
exports.changePasswordEndPoint = "/account_block/accounts/change_password_setting";
exports.changePassVerifyOtpEndPoint = "account_block/accounts/verify_otp_and_change_password";
exports.resendCodeEndPoint = "account_block/accounts";
exports.getSubscriptionPlanEndPoint = "bx_block_subscription_billing/subscription/show_subscription";
exports.cancelPlanEndPoint = "bx_block_subscription_billing/subscription";
exports.paymentProcessingEndPoint = "bx_block_subscription_billing/subscription/update_card";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.getCardDetails = "bx_block_subscription_billing/subscription/get_payment_method"
exports.paymentStatusApi = "bx_block_subscription_billing/subscription/renew_subscription"
exports.btnExampleTitle = "CLICK ME";

exports.settings = {
  settingTxt: "Settings",
  subscriptionTxt: "Subscription billing",
  twoFactorTxt: "Two factor authentication",
  changePassTxt: "Change password",
  termsTxt: "Terms and conditions",
  privacyPolicyTxt: "Privacy policy",
  deleteAccountTxt: "Delete account",
  logoutTxt: "Log out",

  saveTxt: "Save",
  cancelTxt: "Cancel",
  resetTxt: "Reset changes",
}
// Customizable Area End