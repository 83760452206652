//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert } from "react-native";
import {getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

const CategoryData = [
  {
    idss: 0,
    title: "User 1",
    value: 'user_1'
  },
  {
    idss: 1,
    title: "User 2",
    value: 'user_2'

  },
];

interface Imagetype {
  uris: string;
  width: number;
  height: number;
}
interface CategoryType {
  idss: number;
  title: string;
  value: string;
}

interface ProductItem  {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  catagorydata: CategoryType[];
  showCategory: boolean;
  category: CategoryType;
  showModel: boolean;
  images: Imagetype[];
  imagesWeb: File[];
  baseImages: string[];
  title: string;
  description: string;
  price: string;
  quantity: string;
  userDataList:ProductItem[]
  anchorEl:HTMLButtonElement | null;
  currentPage:number;
  cardData:any;
  totalItems: number,
  itemsPerPage: number,
  token:string,
  selectedItem:any,
  documentData:any,
  singleDocument: string,
  renameDocument:boolean,
  renameDocumentError:boolean,
  documentName:string,
  deleteDocument:boolean,
  deleteSuccessDocument:boolean,
  addDocument:boolean,
  documentFile:File | null,
  uploadDocumentName:string,
  addSuccessDocument:boolean,
  inputErrors: {
    titleError: string,
    fileError: string
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  getAllDocumentCallId:string|Message=''
  getSingleDocumentCallId:string|Message=''
  downloadDocumentCallId:string|Message=''
  renameDocumentCallId:string|Message=''
  deleteDocumentCallId:string|Message=''
  uploadDocumentCallId:string|Message=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      isLoading: false,
      catagorydata: CategoryData,
      showCategory: false,
      category: {
        idss: 0,
        title: "User 1",
      value:'user_1'
      },
      showModel: false,
      images: [],
      imagesWeb:[],
      baseImages: [],
      title: "",
      description: "",
      price: "",
      quantity: "",
      userDataList:[],
      anchorEl:null,
      currentPage:1,
      cardData:[],
      totalItems: 0,
      itemsPerPage: 5,
      token:"",
      selectedItem:{},
      documentData:[],
      singleDocument:"",
      renameDocument:false,
      renameDocumentError:false,
      documentName:"",
      deleteDocument:false,
      deleteSuccessDocument:false,
      addDocument:false,
      documentFile:null,
      uploadDocumentName:"",
      addSuccessDocument:false,
      inputErrors: {
        titleError: "",
        fileError: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userDataListCall !== null &&
      this.userDataListCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.userDataRecieve(message)
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addDataCall !== null &&
      this.addDataCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addDataRecieve(message)
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllDocumentCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getAllDocRecieve(message)
    }
    else if (
      this.conditionCheck(getName(MessageEnum.RestAPIResponceMessage) === message.id,this.getSingleDocumentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      this.getSingleDocRecieve(message)
    }
    else if (
      this.conditionCheck(getName(MessageEnum.RestAPIResponceMessage) === message.id,this.renameDocumentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      this.renameDocRecieve(message)
      
    }
    else if (
      this.conditionCheck(getName(MessageEnum.RestAPIResponceMessage) === message.id,this.downloadDocumentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      this.downloadDocRecieve(message)
    }
    else if (
      this.conditionCheck(getName(MessageEnum.RestAPIResponceMessage) === message.id,this.deleteDocumentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      this.deleteDocRecieve(message)
      
    }
    else if (
      this.conditionCheck(getName(MessageEnum.RestAPIResponceMessage) === message.id,this.uploadDocumentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      this.uploadDocRecieve(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const tokenMsg = await getStorageData("token")

    if(tokenMsg){
      this.setState({
        token:tokenMsg
      })
    }
    this.getUserDataListApi()
    this.getHealthWalletApi();
    this.cardGen();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.documentData!==prevState.documentData || this.state.currentPage!==prevState.currentPage){
      this.cardGen();
    }
  }

  userDataRecieve=(message: Message)=>{
    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse1 = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson1) {
      this.setState({
        userDataList: responseJson1.data,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
      this.parseApiErrorResponse(responseJson1);
    }
    this.parseApiCatchErrorResponse(errorResponse1);
  }

  addDataRecieve=(message: Message)=>{
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse2 = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson2) {
      Alert.alert(configJSON.success)
      this.setState({
        loading: false,
        showModel:false
      });
      this.getUserDataListApi()
    } else {
      this.setState({
        loading: false,
      });
      this.parseApiErrorResponse(responseJson2);
    }
    this.parseApiCatchErrorResponse(errorResponse2);
  }

  getAllDocRecieve=(message: Message)=>{
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleResponse(responseJson2)
  }

  handleResponse = (responseJson2) => {
    if (responseJson2.documents) {
      this.handleHealthCardResponse(responseJson2.documents)
    } else if(responseJson2.errors === "No documents available") {
      this.setState({
        isLoading: false,
      });
    } else if(responseJson2.errors || responseJson2.error) {
      this.setState({
        isLoading: false,
      });
      toast.error("Something went wrong. Please try again later.")
      this.parseApiErrorResponse(responseJson2);
    }
  }

  conditionCheck=(cond1,cond2)=>{
    return cond1 && cond2;
  }

  getSingleDocRecieve=(message: Message)=>{
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson2) {
      this.handleSingleHealthDocResponse(responseJson2)
    }
  }

  renameDocRecieve=(message: Message)=>{
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson2) {
      this.handleRenameDocResponse(responseJson2)
    }
  }

  downloadDocRecieve=(message: Message)=>{
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson2) {
      this.handleDownloadDocResponse(responseJson2)
    }
  }

  deleteDocRecieve=(message: Message)=>{
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson2) {
      this.handleDeleteDocResponse(responseJson2)
    }
  }

  uploadDocRecieve=(message: Message)=>{
    this.setState({isLoading: false});
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson2) {
      this.handleUploadDocResponse(responseJson2)
    }
  }

  getUserDataListApi = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDataListCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl+this.state.category.value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postAddDataApi=async()=>{
    const header = {
    };
    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("description", this.state.description)
    formData.append("price", this.state.price);
    formData.append("quantity", this.state.quantity)
    formData.append("user_type", this.state.category.value)

    if(this.isPlatformWeb()){
      this.state.imagesWeb.forEach((item: File) => {
        formData.append("images[]", item)
      })
    }else{
      this.state.images.forEach((item: Imagetype) => {
        formData.append("images[]", {
          uri: item.uris,
          type: "image/png",
          name: "image"
        })
      })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  showCategory = () =>
    this.setState({
      showCategory: !this.state.showCategory,
    });

  onSelectCategory = (item: CategoryType) => {
    this.setState({ category: item, showCategory: false },()=>{
      this.getUserDataListApi()
    });
  };

  onSelectCategoryWeb = (value: string) => {
    const filteredData = this.state.catagorydata.find((finddd) => finddd.value === value)
    filteredData && this.setState({ category: filteredData },()=>{
      this.getUserDataListApi()
    });
  };


  addNewProduct = () => this.setState({ showModel: true });

  hideModal = () => this.setState({ showModel: false });

  openImagePicker = () => {
    
  };

  onChangeImage = (event:React.ChangeEvent) => {
    const targetData= event.target as HTMLInputElement;
    const fileValue: File = (targetData.files as FileList)[0];
    const base64Img = URL.createObjectURL(fileValue)
    this.setState({ imagesWeb: [...this.state.imagesWeb, fileValue], baseImages: [...this.state.baseImages, base64Img] });
  };

  onChangeTitle = (text: string) => {
    this.setState({ title: text });
  };

  onChangeDescription = (text: string) => {
    this.setState({ description: text });
  };

  onChangePrice = (text: string) => {
    this.setState({ price: text });
  };

  onChangeQuantity = (text: string) => {
    this.setState({ quantity: text });
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>,item:any) => {
    this.setState({
      anchorEl:event.currentTarget,
      selectedItem: item
    })
  };

  handleClose = () => {
    this.setState({
      anchorEl:null
    })
  };

  handleViewDialogClose=()=>{
    this.setState({
      viewDocument:false,
      anchorEl:null
    }) 
  }

  handleRenameDialogClose=()=>{
    this.setState({
      renameDocument:false,
      anchorEl:null
    }) 
  }

  handleDeleteDialogClose=()=>{
    this.setState({
      deleteDocument:false,
      anchorEl:null
    }) 
  }

  handleAddDialogClose=()=>{
    this.setState({
      addDocument:false,
    }) 
  }

  handleDeleteSuccessDialogClose=()=>{
    this.setState({
      deleteSuccessDocument:false,
    }) 
  }

  handleUploadSuccessDialogClose=()=>{
    this.setState({
      addSuccessDocument:false,
    }) 
  }

  
  handleView=async(docId:number)=>{
    this.showHealthWalletDocApi(docId)
  }

  handleDownload= async (file: {name: string, url: string})=>{
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleRename=(docId:number)=>{
    this.setState({
      renameDocument:true,
    })
  }
  

  handleDelete=(docId:number)=>{
    this.setState({
      deleteDocument:true,
    })
  }

  handleAdd=()=>{
    this.setState({
      addDocument:true,
    })
  }

  handleDocumentName=(e)=>{
    this.setState({
      documentName:e.target.value
    })
    if(e.target.value){
      this.setState({
        renameDocumentError:false
      })
    }
  }

  handleUploadDocumentName=(e)=>{
    this.setState({
      uploadDocumentName:e.target.value
    })
  }

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (selectedFile) {
      const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
      if (!validTypes.includes(selectedFile.type)) {
        this.setState({inputErrors: {...this.state.inputErrors, fileError: 'Only pdf, jpg and png files are allowed.'}, documentFile: null}, () => {
          setTimeout(() => this.setState({inputErrors: {...this.state.inputErrors, fileError: ""}}), 3000)
        });
        return
      }
      else if (selectedFile.size > maxSizeInBytes) {
        this.setState({inputErrors: {...this.state.inputErrors, fileError: "Couldn't upload image, file size is too large"}, documentFile: null}, () => {
          setTimeout(() => this.setState({inputErrors: {...this.state.inputErrors, fileError: ""}}), 3000)
        });
        return
      }
      this.setState({
        documentFile:selectedFile,
        inputErrors: {...this.state.inputErrors, fileError: ""}
      })
    }
  };

  handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files?.[0]; 
    if (droppedFile) {
      this.setState({
        documentFile:droppedFile
      })
    }
  };

  renderErrorMessage = () => {
    if (this.state.renameDocumentError) {
        return "Rename document field cannot be empty"
    }
    return null;
  };

  cardGen = () => {
    const { currentPage, itemsPerPage, documentData } = this.state;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = documentData?.slice(startIndex, endIndex);
    this.setState({ cardData: itemsToDisplay ,totalItems: documentData?.length});
  };

  handlePageChange = (event, value) => {
    this.setState({ currentPage: value });
  };

  handleHealthCardResponse=(documents: any)=>{
    this.setState({
      documentData:documents.filter((item: WalletResponse) => !item.document_url.error && item),
      isLoading: false
    },()=>this.cardGen())
  }

  handleRenameDocResponse=async(responseJson:any)=>{
    if(responseJson.success){
      this.setState({
        renameDocument:false,
        anchorEl:null,
        documentName: ""
      }, async () => await this.getHealthWalletApi())
    }
  }

  handleDeleteDocResponse=async(responseJson:any)=>{
    if(responseJson.result.success){
      this.setState({
        isLoading: false,
        deleteDocument:false,
        deleteSuccessDocument:true,
        anchorEl:null,
      }, () => {
        setTimeout(() => {
          this.setState({deleteSuccessDocument: false}, async () => await this.getHealthWalletApi())
        }, 2500)
      })
    }
  }

  handleUploadDocResponse=async(responseJson:any)=>{
    if(responseJson.document.id){
      this.setState({
        addSuccessDocument:true,
        documentFile: null,
        uploadDocumentName: ""
      }, () => {
        setTimeout(() => {
          this.setState({addSuccessDocument: false}, async () => await this.getHealthWalletApi())
        }, 2500)
      })
    }
  }

  handleSingleHealthDocResponse=(responseJson:any)=>{
    this.setState({
      singleDocument:responseJson?.document_url?.url,
      viewDocument:true,
      isLoading: false
    })
  }

  getHealthWalletApi = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDocumentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/wallet/show_all_documents"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  showHealthWalletDocApi = async (docId:number) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleDocumentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/wallet/${docId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  renameDocApi= async (docId:number) => {
    if(this.state.documentName===""){
      this.setState({
        renameDocumentError:true
      })
    }
    else{
      const header = {
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const formData = new FormData();
      formData.append("document_name", this.state.documentName)

      this.renameDocumentCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/account_block/accounts/wallet/${docId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteDocApi= async (docId:number) => {
    this.setState({deleteDocument: false, isLoading: true});
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDocumentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/wallet/${docId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  anyErrors = (errorObj: any) => {
    for (let arr of Object.entries(errorObj)) {
      let val = arr[1]
      if (val) {
        return true;
      }
    }
  }
  validateForm = () => {
    let inputErrors = {
      titleError: "",
      fileError: ""
    }
    if(!this.state.uploadDocumentName) {
      inputErrors = {
        ...inputErrors,
        titleError: "Please enter a document name"
      }
    }
    if(!this.state.documentFile) {
      inputErrors = {
        ...inputErrors,
        fileError: "Please choose a document"
      }
    }
    this.setState({inputErrors})
    const anyErrors = this.anyErrors(inputErrors);
    if (anyErrors) {
      return false;
    }
    return true;
  }

  uploadDocApi= async () => {
    if(this.validateForm()){
      this.setState({isLoading: true, addDocument: false})
      const header = {
        token: this.state.token,
      };
      const formData = new FormData();
      formData.append("fileData[]", this.state.documentFile)
      formData.append("title", this.state.uploadDocumentName)
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.uploadDocumentCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/account_block/accounts/wallet`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  // Customizable Area End
}
