// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import SetUpSubscription from "../../blocks/subscriptionbilling2/src/SetUpSubscription.web";
import AddSubscription from "../../blocks/subscriptionbilling2/src/AddSubscription.web";
import PaymentConfirmation from "../../blocks/subscriptionbilling2/src/PaymentConfirmation.web";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ForgotPasswordOTPWeb from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import NewPasswordWeb from "../../blocks/forgot-password/src/NewPassword.web";
import Settings2 from "../../blocks/settings2/src/Settings2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import AddNewCaseForm from '../../blocks/customform/src/AddNewCaseForm.web';
import ViewCaseDetails from '../../blocks/customform/src/ViewCaseDetails.web';
import CustomForm from '../../blocks/customform/src/CustomForm.web'
import CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web'
import OTPVerifyAuth from '../../blocks/customisableuserprofiles/src/OTPVerifyAuth.web'
import OTPVerification from '../../blocks/email-account-login/src/OTPVerification.web'
import ContentManagement from '../../blocks/contentmanagement/src/ContentManagement.web'
import ExistingMemberRegistration from '../../blocks/email-account-registration/src/ExistingMemberRegistration.web'
import ExistingMemberOTPInputAuth from '../../blocks/email-account-registration/src/OTPInputAuth.web'
import PendingVerification from '../../blocks/email-account-login/src/PendingVerification.web'
import PendingVerifyOtp from '../../blocks/email-account-login/src/PendingVerifyOtp.web'

const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
OTPVerifyAuth:{
 component:OTPVerifyAuth,
path:"/OTPVerifyAuth"},
OTPVerification:{
  component:OTPVerification,
 path:"/OTPVerification"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ExistingMemberOTPInputAuth:{
 component:ExistingMemberOTPInputAuth,
path:"/ExistingMemberOTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ExistingMemberRegistration:{
 component:ExistingMemberRegistration,
path:"/ExistingMemberRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
ForgotPasswordWeb:{
  component: ForgotPasswordWeb,
  path:"/ForgotPasswordWeb"
},
ForgotPasswordOTPWeb:{
  component: ForgotPasswordOTPWeb,
  path:"/ForgotPasswordOTPWeb"
},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
NewPasswordWeb:{
  component:NewPasswordWeb,
 path:"/NewPasswordWeb"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
AddNewCaseForm:{
  component:AddNewCaseForm,
 path:"/AddCase"},
  CustomForm: {
    component: CustomForm,
    path: "/CustomForm"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  ViewCaseDetails: {
    component: ViewCaseDetails,
    path: "/ViewCaseDetails"
  },
  PendingVerification:{
    component: PendingVerification,
    path: "/PendingVerification"
  },
  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  SetUpSubscription: {
    component: SetUpSubscription,
    path: "/SetupSubscription"
  },
  AddSubscription: {
    component: AddSubscription,
    path: "/AddSubscription"
  },
  PaymentConfirmation: {
    component: PaymentConfirmation,
    path: "/PaymentConfirmation"
  },
  PendingVerifyOtp:{
    component: PendingVerifyOtp,
    path: "/PendingVerifyOtp"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100%' }}>
        {/* <TopNav /> */}
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;